angular.module('extra2App')
    .controller('CommPrefPreStepController', ['$window', '$location', '$scope', '$translatePartialLoader', '$translate', 'cardservice', 'page', 'restservice', 'notificationservice',
        function ($window, $location, $scope, $translatePartialLoader, $translate, cardservice, page, restservice, notificationservice) {

            $translatePartialLoader.addPart('termsconditions');
            $translate.refresh();

            page.setTitle($translate.instant('terms_conditions.prestep.title'));

            restservice.getCommunicationPreferences().then(function (result) {
                $scope.emailAddress = result.data.emailAddress;
                $scope.mobileNumber = result.data.mobileNumber;
                $scope.emailEnabled = false;
                $scope.smsEnabled = false;
                $scope.pushEnabled = false;
                $scope.surveyEnabled = false;
            });

            $scope.setCommunicationPreferences = function (email, sms, push) {
                restservice.setCommunicationPreferences(email, sms, push, email).then(function(response) {
                    if (response.status === 202) {
                        notificationservice.setNotification('profile.communication_preferences.warning.deferred_saving', 'warning');
                    }
                    $window.location.href = '#/dashboard';
                });
            };
        }]);

angular
  .module('extra2App')
  .directive('ckHeader', ckHeader);

function ckHeader($location, $translatePartialLoader, $translate, page) {

  return {
    templateUrl: 'views/directives/header.html',
    restrict: 'E',
    link: link
  }

  function link(scope) {
    scope.getPageTitle = getPageTitle;
    scope.isLogo = true;
    scope.profileVisible = false;
    let isDashboard = false;
    let isMastercard = false;

    $translatePartialLoader.addPart('extra2ui');
    $translate.refresh();
    const noLogoPaths = new Set(['/profile', '/rolling-models-detail', '/points-history', '/awards-detail', '/my-transactions', '/tiers-transactions', '/tiers-info']);

    setHeaderForPath();
    scope.$on('$routeChangeStart', setHeaderForPath);

    scope.$watch(isLoading, updateProfileVisibility);

    function setHeaderForPath() {
      const path = $location.path();
      isDashboard = !!path.match('dashboard');
      isMastercard = !!path.match('mastercard-redirect');
      scope.isLogo = isDashboard || !noLogoPaths.has(path);
      updateProfileVisibility();
    }

    function updateProfileVisibility() {
      scope.profileVisible = (isDashboard && !page.isLoading()) || isMastercard;
    }

    function isLoading() {
      return page.isLoading();
    }

    function getPageTitle() {
      return page.title();
    }
  }
}

'use strict';

angular.module('extra2App')
   .directive('ckNextIcon', [function () {
	return {
		templateUrl: 'views/directives/icons/next.html',
		templateNamespace: 'svg',
		restrict: 'E',
		replace: true
	};
}]);
'use strict';

angular.module('extra2App')
    .directive('ckRollingmodels', ['$location', 'restservice', 'page', '$translatePartialLoader', '$translate', 'koppenservice', function ($location, restservice, page, $translatePartialLoader, $translate, koppenservice) {
        return {
            templateUrl: 'views/directives/rolling-models.html',
            restrict: 'E',
            scope: {module: '='},
            link: function (scope) {

                $translatePartialLoader.addPart('rollingmodels');
                $translate.refresh();

                scope.rollingModels = scope.module[1].values;

                scope.iconsBasePath = restservice.getIconsBasePath();

                scope.koppen = koppenservice.getKoppen(scope.rollingModels);

                scope.showRollingDetails = function (item) {
                    item.iconsBasePath = scope.iconsBasePath;
                    localStorage.setItem("rolling_model", JSON.stringify(item));
                    $location.path('rolling-models-detail');
                }
            }
        };
    }]);

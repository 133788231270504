angular.module('extra2App')
   .controller('AdminController', ['$window', '$scope', 'restservice', 'adminservice', '$translatePartialLoader', '$translate', 'page', '$location', '$http',
      function ($window, $scope, restservice, adminservice, $translatePartialLoader, $translate, page, $location, $http) {

        restservice.getSettings().then(function (result) {
          restservice.getModuleData(result.apiAdminURI + 'adminForm').then(
              function (response) {
                if (response.status !== 200) {
                  $window.location.href = '/#/dashboard';
                }
              });
        });

         $translatePartialLoader.addPart('../../admin');
         $translate.refresh();
         page.setTitle('admin.page_title');
         var businessUnit = localStorage.getItem("businessUnit");

         $scope.business_units = [
            { name: "SE", id: 1 },
            { name: "NO", id: 2 },
            { name: "DK", id: 3 },
            { name: "EE", id: 4 },
            { name: "LV", id: 5 },
            { name: "LT", id: 6 },
            { name: "PL", id: 7 }
         ];

         $scope.currentTab = (sessionStorage.newTab === undefined) ? 'banners' : sessionStorage.newTab;

         $scope.$watch('currentTab', function(val) {
            sessionStorage.newTab = val;
            $scope.selectBu(sessionStorage.selectedBusinessUnit);
         });

         for (var i in $scope.business_units) {
            if (sessionStorage.selectedBusinessUnit === undefined) {
               if ($scope.business_units[i].name === businessUnit) {
                  $scope.selectedOption = $scope.business_units[i];
               }
            } else if ($scope.business_units[i].name === sessionStorage.selectedBusinessUnit) {
               $scope.selectedOption = $scope.business_units[i];
            }
         }

         $scope.selectBu = function(businessUnit) {
            sessionStorage.selectedBusinessUnit = businessUnit;
            restservice.getSettings().then(function (result) {
               restservice.getModuleData(result.apiAdminURI + result.adminContentsUri + $scope.currentTab + '/' + businessUnit).then(function (response) {
                  if (response.status === 200) {
                     for (var item in response.data) {
                        var obj = response.data[item];
                        if(obj.contentType === 'BANNERS') {
                           $scope.$broadcast('banners', obj.value);
                           $scope.$broadcast('bu', $scope.selectedOption);
                        }
                        if(obj.contentType === 'VOUCHERS') {
                           $scope.$broadcast('vouchers', obj.value);
                           $scope.$broadcast('bu', $scope.selectedOption);
                        }
                     }
                  }
               });
            });
         };

         restservice.getAdminRole().then(function(adminRole) {
            $scope.buSelectEnabled = (adminRole !== "superAdmin");
         });

         $scope.isDisabled = function() {
           return $scope.buSelectEnabled ? 'not-allowed' : 'default';
         };
}]);

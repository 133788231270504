angular.module('extra2App')
  .controller('TermsConditionsController', ['$scope', '$sce', '$window', '$location', '$translatePartialLoader', '$translate', 'restservice', 'notificationservice', 'page', 'agreementservice', '$cookies',
    function($scope, $sce, $window, $location, $translatePartialLoader, $translate, restservice, notificationservice, page, agreementservice, $cookies) {

      $translatePartialLoader.addPart('profile');
      $translate.refresh();
      $scope.showHide = agreementservice.getShowHideTcButton();
      $scope.agreement = JSON.parse(localStorage.getItem("selectedAgreement"));
      var partnerInfoId;

      if($cookies.get('PART_AGREE')){
        partnerInfoId = JSON.parse($cookies.get('PART_AGREE'))['partnerInfoId'];
      }

      page.setTitle($translate.instant('profile.terms_conditions'));
      $scope.terms_and_conditions_body = $sce.trustAsHtml($scope.agreement.tc);

      $scope.showProfile = function() {
        restservice.redirectWhenDecline();
      };

      $scope.back = function() {
        page.back();
      };

      $scope.acceptTC = function() {
        if ($scope.agreement.hasActiveAgreement === true) {
          if (confirm($translate.instant('profile.company_agreement_code.replace_dialog'))) {
            $scope.callAgreementRestservice($scope.agreement);
          }
        }
        else {
          $scope.callAgreementRestservice($scope.agreement);
        }
      };

      $scope.callAgreementRestservice = function(agreement) {
        restservice.acceptAgreementCode(agreement, partnerInfoId).then(function(response) {
          if (response.status === 200) {
            $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.exists');
            var agreementActivation;
            var agreeCookie = $cookies.get('PART_AGREE');
            if (agreeCookie) {
              agreementActivation = JSON.parse(agreeCookie);
            }
            if (agreementActivation) {
              $location.path('/activate-agreement');
            } else {
                page.gotoView(page.profileView);
            }
          } else if (response.status === 400 || response.status === 412 || response.status === 403) {
              notificationservice.setNotification('profile.company_agreement_code.bad_code', 'warning');
          } else if (response.status === 429) {
              notificationservice.setNotification('profile.company_agreement_code.warn_timeout', 'warning');
          }
        });
      };

      restservice.getCommunicationPreferences().then(function(result) {
         var memberProfile = result.data;
         $scope.displayName = memberProfile.firstName + " " + memberProfile.lastName;
         $scope.displayEmail = "(" + memberProfile.emailAddress + ")";
      })
   }
  ]);

'use strict';

angular.module('extra2App')
   .directive('ckAdminVouchers', ['$location','restservice', 'page', 'localizationservice', '$route', '$translate', 'httpPostFactory',
      function ($location, restservice, page, localizationservice, $route, $translate, httpPostFactory) {
         return {
            templateUrl: 'views/directives/admin-vouchers.html',
            restrict: 'E',
            scope: { module: '=' },
            link: function (scope, element, attributes) {

               scope.showAddVoucherView = false;
               scope.isEdit = false;
               scope.createVoucher = {};
               scope.title = "";
               var businessUnit = localStorage.getItem("businessUnit");

               var setHeader = function(id) {
                  scope.title = $translate.instant(id ? 'admin.vouchers_edit' : 'admin.vouchers_add');
                  if (id) {
                     scope.title += " '" + id.productGroupId + "'";
                  }
               };

               var addVoucher = function() {
                  scope.showAddVoucherView = true;
               };

               var editVoucher = function(id) {
                  scope.createVoucher = id;
                  scope.createVoucher.activationDate = new Date(scope.createVoucher.activationDate);
                  scope.createVoucher.expirationDate = new Date(scope.createVoucher.expirationDate);
                  scope.showAddVoucherView = true;
               };

               var reloadVouchers = function(response) {
                  if(response.status === 200){
                     $route.reload();
                  }
               };

               restservice.getSettings().then(function (result) {
                  restservice.getModuleData(result.apiAdminURI + result.adminContentsUri + "vouchers/" + businessUnit).then(function (response) {
                     if (response.status === 200) {
                        for (var item in response.data) {
                           var obj = response.data[item];
                           if(obj.contentType === 'VOUCHERS'){
                              scope.voucherData = obj.value;
                           }
                        }
                     }
                  });
               });

               scope.$on('vouchers', function (event, data) {
                  scope.voucherData = data;
               });

               scope.$on('bu', function (event, data) {
                  businessUnit = data.name;
               });

               scope.submit = function() {
                  if (scope.isEdit) {
                     updateVoucher();
                  } else {
                     scope.createVoucher.countryCode = businessUnit;
                     scope.createVoucher.contentType = 'VOUCHERS';
                     scope.createVoucher.altText = scope.createVoucher.title;
                     restservice.uploadAdminContent(scope.createVoucher).then(function (response) {
                        reloadVouchers(response);
                     });
                  }
                  scope.isEdit = false;
               };

               var updateVoucher = function() {
                  restservice.updateAdminContent(scope.createVoucher).then(function (response) {
                     reloadVouchers(response);
                  });
               };

               scope.openEditVoucherView = function(id) {
                  if (id === undefined) {
                     addVoucher();
                  } else {
                    scope.isEdit = true;
                     editVoucher(id);
                  }
                  setHeader(id);
               };

               scope.isPublished = function(item) {
                  return($translate.instant(item.publish ? 'admin.item_published' : 'admin.item_unpublished'));
               };

               scope.deleteVoucher = function(voucher) {
                  if (confirm($translate.instant('admin.vouchers_confirm_delete'))) {
                     restservice.getSettings().then(function (result) {
                        restservice.deleteModuleData(result.deleteContent + businessUnit + "/" + voucher.contentId).then(function (response) {
                           reloadVouchers(response);
                        });
                     });
                  }
               };

               scope.discard = function() {
                  scope.createVoucher = {};
                  scope.showAddVoucherView = false;
                  scope.isEdit = false;
               };

               scope.getDate = function() {
                  var today = new Date();
                  var dd = today.getDate();
                  var mm = today.getMonth()+1;
                  var yyyy = today.getFullYear();

                  if(dd < 10) {
                     dd = '0'+ dd;
                  }

                  if(mm < 10) {
                     mm = '0'+ mm;
                  }

                  today = yyyy + '-' + mm + '-' + dd;
                  return(today);
               };
            }};
         }]);

'use strict';

angular.module('extra2App').directive('ckBackButton', ['$location', '$route', function ($location, $route) {
    return {
      template: '<a ng-show="visible" ng-click="goBack()"><svg xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M10.5 1l-9 10 9 10" stroke-linecap="round" stroke-width="1.25" stroke-linejoin="round"/></g></svg></a>',
      restrict: 'E',
      scope: { homeroutepath: '@'},
      link: function (scope) {

       scope.$on("$routeChangeSuccess", function () {
         setVisibility();
       });

        var setVisibility = function() {
          scope.visible  = !($route.current.$$route.originalPath === scope.homeroutepath);
        }

        scope.goBack = function() {
          $location.path('/dashboard');
        }

        setVisibility();
      }
    };
  }]);

angular.module('extra2App')
   .controller('ExtraMemberPageController', ['$scope', 'restservice', '$translatePartialLoader', '$translate', '$location', '$cookies', 'page',
   function ($scope, restservice, $translatePartialLoader, $translate, $location, $cookies, page) {

        $translatePartialLoader.addPart('extra2ui');
        $translate.refresh();

       page.setTitle("");
       $cookies.remove('REMAIN_MEMBER');

       $location.url($location.path());

       restservice.remainMember();

       $scope.goToDashboard = function() {

           $location.path('/dashboard');
        }

        $scope.logout = function () {
            restservice.logout();
        };

   }]);

'use strict';

angular.module('extra2App')
.directive('ckTiers', ['$location', 'restservice', 'page', '$translatePartialLoader', '$translate', function ($location, restservice, page, $translatePartialLoader, $translate) {
    return {
        templateUrl: 'views/directives/tiers.html',
        restrict: 'E',
        scope: {module: '='},
        link: function (scope) {

            $translatePartialLoader.addPart('tier');
            $translate.refresh();

            scope.tiersData = scope.module[1];
            scope.messages = scope.module[1].profile.messages;
            scope.profile = scope.module[1].profile;

            scope.currentLevel = scope.profile.currentLevel === 0 ? 1 : scope.profile.currentLevel;
            scope.iconsBasePath = restservice.getIconsBasePath();
            scope.parachute = scope.profile.parachute ? '_parachute' : '';
            scope.levelIcon = 'images/tiers/tier_all_' + scope.currentLevel + scope.parachute + '.svg';
            scope.levels = scope.module[1].levels;
            scope.levels_title = scope.module[1].levels_title;

            scope.goToInfo = function() {
                localStorage.setItem("tiers_levels", JSON.stringify(scope.levels));
                localStorage.setItem("levels_title", JSON.stringify(scope.levels_title));
                $location.path('/tiers-info');
            }

            scope.goToTiersTransactions = function() {
                $location.path('/tiers-transactions');
            }
        }
    };
}]);

angular.module('extra2App').filter('trust_as_html', ['$sce', function($sce) {
    return function(input) {
        return $sce.trustAsHtml(input);
    };
}]);

angular.module('extra2App')
    .controller('ProfileController', ['$scope', '$window', '$location', '$translatePartialLoader', '$translate', 'cardservice', 'restservice', 'page', 'agreementservice',
        function ($scope, $window, $location, $translatePartialLoader, $translate, cardservice, restservice, page, agreementservice) {

            $translatePartialLoader.addPart('profile');
            $translate.refresh();

            $scope.pointsExist = localStorage.getItem("pointsExist") == "true";

            page.setTitle($translate.instant('profile.title'));

            $scope.profile = {};
            $scope.profile.displayName = "";

            $scope.paymentCards = [];
            $scope.agreementCodes = [];

            $scope.showMasterCardLoginButton = false;

            var circleKidUpdateProfileUrl = "";
            restservice.getSettings().then(function (settings) {
                circleKidUpdateProfileUrl = settings.circleKidUpdateProfileUrl;
            });

            var getMemberProfile = (function () {
                restservice.getMemberProfile().then(function (result) {

                    var memberProfile = result.data;
                    localStorage.setItem("businessUnit", memberProfile.businessUnit);

                    var agreementCodes = memberProfile.agreementCodeList;
                    if (agreementCodes) {
                        $scope.agreementCodes = agreementCodes;
                        $scope.agreementCodeExists = true;
                    }

                    $scope.profile.displayName = memberProfile.firstName + " " + memberProfile.lastName;
                    $scope.profile.email = memberProfile.emailAddress;
                    $scope.profile.phoneNumber = memberProfile.mobileNumber;

                    // Payment cards
                    if (memberProfile.paymentCards.length >= 1) {
                        $scope.paymentCards = memberProfile.paymentCards;
                    }

                    // Putting a space (" ") after every fourth character
                    angular.forEach($scope.paymentCards, function (card) {
                        card.cardDetails = card.maskedCardNumber.replace(/(.{4})/g, '$1 ').trim();
                        if (card.authenticationPending) {
                            $scope.authenticationPending = $translate.instant('profile.card_authentication_pending');
                        }
                        if (card.expiryDate) {
                            card.expiryDate = card.expiryDate.substring(4) + "/" + card.expiryDate.substring(2, 4)
                        } else if (card.cardDetails.includes("*")){
                            card.expiryDate = " -/-"
                        }

                        if (card.cmc) {
                            card.state = 'cmc';
                            $scope.showMasterCardLoginButton = true;
                        }
                    })
                });
            }());

            $scope.editAccount = function () {
                if (circleKidUpdateProfileUrl !== "")
                    $window.open(circleKidUpdateProfileUrl, "_self");
            };

            $scope.linkCard = function () {
                cardservice.initLinkCard('profile');
            };

            $scope.showTermsContidions = function () {
                $location.path('/terms-and-conditions');
            };

            $scope.openMasterCardLoginUrl = function () {
                $translate('extra2ui.mastercard_login.link_path').then(function (translation) {
                    $window.open(translation);
                });
            };

            $scope.showAgreementCodePage = function (agreement) {
                // agreementservice.setAgreement(agreementCodeValue);
                localStorage.setItem("selectedAgreement", JSON.stringify(agreement));
                $location.path('/agreement-code');
            };

            $scope.showCommunicationPreferences = function () {
                $location.path('/communication-preferences');
            };

            $scope.showMyTransactions = function (val) {
                localStorage.setItem("selected_graph", JSON.stringify(val));
                $location.path('/my-transactions');
            };

            $scope.showPoints = function () {
                $location.path('/points-history');
            };

            $scope.showCardSubpage = function (paymentCard) {
                localStorage.setItem("paymentCard", JSON.stringify(paymentCard));
                localStorage.setItem("moreThanOneCard", $scope.paymentCards.length > 1);

                $location.path('/card-subpage');
            };

            $scope.addAgreementCode = function () {
                $location.path('/add-agreement-code');
            };
        }]);

export const draftStatusToMessageKey = {
  400: 'extra2ui.draft.processed',
  404: 'extra2ui.draft.notFound',
  410: 'extra2ui.draft.expired'
}

angular
  .module('extra2App')
  .factory('DashboardService', DashboardService);

function DashboardService($window, $location, $cookies, restservice, notificationservice) {

  return {
    supportWarnings,
    supportQueryParamAction,
    supportPartnerStrategies,
  }

  function supportPartnerStrategies() {
    remainMember();
    deprecatedActivateAgreement();
    activateAgreement();
  }

  function supportQueryParamAction() {
    const queryParamsObject = $location.search();
    const actionQueryParam = queryParamsObject['action'];
    if (actionQueryParam) {
      if (actionQueryParam === 'remain_member') {
        $cookies.put('REMAIN_MEMBER', JSON.stringify(queryParamsObject));
      } else if (actionQueryParam === 'consent_to_agreement') {
        $cookies.put('PART_AGREE', JSON.stringify(queryParamsObject), {'expires': getExpiration()});
      } else if (actionQueryParam === 'partner_agreement') {
        const expirationMinutes = queryParamsObject['expiration_period_minutes'];
        const agreementCode = queryParamsObject['agreement_code'];
        $cookies.put('PARTNER_AGREEMENT', JSON.stringify(queryParamsObject), {'expires': getExpiration(expirationMinutes)});
        if (agreementCode.startsWith('MASTERCARD')) {
          setMastercardRequestedCookie();
        }
      }
    }
  }

  function activateAgreement() {
    const agreementCookie = $cookies.get('PARTNER_AGREEMENT');
    if (agreementCookie) {
      const agreementCookieData = JSON.parse(agreementCookie);
      if (agreementCookieData) {
        restservice.authorizePartner(agreementCookieData['agreement_draft_id']).then(
          response => {
            if (!isMastercardRequested()) {
              $cookies.remove('PARTNER_AGREEMENT');
              $cookies.remove('MASTERCARD');
            }
            if (response.status === 200) {
              $window.location.href = response.data.location;
            } else if (draftStatusToMessageKey.hasOwnProperty(response.status)) {
              mastercardNotSuccessfullyRequested();
              notificationservice.setNotification(draftStatusToMessageKey[response.status], 'warning');
            } else {
              mastercardNotSuccessfullyRequested();
              $location.path('/global-failure-page');
            }
          }
        );
      }
    }
  }

  function deprecatedActivateAgreement() {
    let agreementActivation;
    const agreeCookie = $cookies.get('PART_AGREE');
    if (agreeCookie) {
      agreementActivation = JSON.parse(agreeCookie);
    }
    if (agreementActivation) {
      $location.path('/activate-agreement');
    }
  }

  function remainMember() {
    const remainMemberCookie = $cookies.get('REMAIN_MEMBER');
    if (remainMemberCookie) {
      if (JSON.parse(remainMemberCookie)) {
        $location.path('/remainmember');
      }
    }
  }

  function supportWarnings(warnings) {
    if (warnings.includes('MARKETING_CONSENT')) {
      $location.path('/communication-preferences-prestep');
    } else if (warnings.includes('NO_CARD') && !isMastercardRequested()) {
      restservice.triggerInitiateLinkCard('dashboard');
    } else {
      supportPartnerStrategies();
    }
  }

  function getExpiration(expirationMinutes = 10) {
    const expiryDate = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + expirationMinutes);
    return expiryDate;
  }

  function setMastercardRequestedCookie() {
    $cookies.put('MASTERCARD', 'requested');
  }

  function isMastercardRequested() {
    return 'requested' === $cookies.get('MASTERCARD');
  }

  function mastercardNotSuccessfullyRequested() {
    $cookies.remove('MASTERCARD');
  }
}

'use strict';

angular.module('extra2App').factory('transactionservice', ['$q','restservice', 'localizationservice',
    function($q, restservice, localizationservice){

        var loadPurchases = function(selectedMonth) {

            if (selectedMonth && !selectedMonth.loaded) {
                restservice.getPurchases(selectedMonth.year, selectedMonth.month, true).then(function(result) {
                    selectedMonth.loaded = true;
                    selectedMonth.purchases = [];
                    selectedMonth.totalFuel = 0;
                    if (result.data) {
                        // different browsers find either null or string "undefined":
                        if ((localStorage.getItem("fuel_value_data") != null) && localStorage.getItem("fuel_value_data") != "undefined") {
                            var fuelValueData = JSON.parse(localStorage.getItem("fuel_value_data"));
                            var filterCurrentResult = fuelValueData.filter(function(fData) { return fData.year == selectedMonth.year && fData.month == selectedMonth.month; });
                            selectedMonth.totalFuel = filterCurrentResult.length > 0 ? Math.round(filterCurrentResult[0].value) : 0;
                        }

                        selectedMonth.noTransactions = result.data.totalSpent == 0 ? true : false;

                        selectedMonth.totalCostText = result.data.totalSpent.toFixed(2).toString().replace(".", ",");
                        selectedMonth.localCurrencyCode = result.data.currencyCode;
                        if (result.data.purchases) {
                            result.data.purchases.forEach(function(purchaseResult) {
                                var purchase = purchaseResult;
                                var totalPurchaseAmount = Number(purchase.totalPurchaseAmount);
                                // EXTTWO-1159: As an Extra member I want the points overview to only display relevant information
                                // Value 0.01 in Poland is caused by value needed for printing receipt
                                    purchase.purchaseDate = new Date(purchase.purchaseDate + "+00:00"); // EXTTWO-1742...otherwise time is in wrong timezone.
                                    purchase.productGroupTitle = localizationservice.getLocalTitleOfTranslationId(purchase.productGroupIcon);
                                    purchase.productAmountText = totalPurchaseAmount.toFixed(2).toString().replace(".", ",");

                                    if (totalPurchaseAmount < 0) {
                                        purchase.state = 'refund';
                                        purchase.productAmountText = purchase.productAmountText.substring(1);
                                    } else {
                                        purchase.productAmountText = "-" + totalPurchaseAmount.toFixed(2).toString().replace(".", ",");
                                    }
                                    if(totalPurchaseAmount != 0)
                                        selectedMonth.purchases.push(purchase);

                            })
                        }
                    }
                })
            }
        };

        var loadPoints = function(selectedMonth) {

            if (selectedMonth && !selectedMonth.loaded) {
                restservice.getPurchases(selectedMonth.year, selectedMonth.month, false).then(function(result) {
                    selectedMonth.loaded = true;
                    selectedMonth.purchases = [];
                    selectedMonth.totalPointsEarned = 0;
                    selectedMonth.totalPointsSpent = 0;

                    if (result.data && result.data.purchases) {
                        result.data.purchases.forEach(function(purchaseResult) {
                            var purchase = purchaseResult;

                            if (purchase.points != 0) {

                                purchase.purchaseDate = new Date(purchase.purchaseDate + "+00:00"); // EXTTWO-1742...otherwise time is in wrong timezone.
                                purchase.productGroupTitle = localizationservice.getLocalTitleOfTranslationId(purchase.productGroupIcon);
                                if (purchase.points > 0) {
                                    purchase.pointsText = "+" + purchase.points;
                                    purchase.state = 'use-points';
                                }
                                else {
                                    purchase.pointsText = purchase.points;
                                }
                                selectedMonth.purchases.push(purchase);
                            }
                        });
                        selectedMonth.totalPointsEarned += result.data.totalPointsEarned;
                        selectedMonth.totalPointsSpent += result.data.totalPointsSpent;
                    }
                })
            }
        };

        return {
            loadPurchases: loadPurchases,
            loadPoints: loadPoints
        };
    }]);
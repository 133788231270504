angular.module('extra2App')
    .controller('LinkAccountByCardController', ['$scope', '$window', '$location', '$translatePartialLoader', '$translate', 'restservice', 'page',
        function ($scope, $window, $location, $translatePartialLoader, $translate, restservice, page) {

            $translatePartialLoader.addPart('linkcard');
            $translate.refresh();

            page.setTitle($translate.instant('link_card.link_account_by_card.text'));

            $scope.showConfirmationLabel = false;

            $scope.linkAccountByCard = function (cardNumberValue) {

                $scope.showConfirmationLabel = false;
                restservice.linkAccountByCard(cardNumberValue).then(function(response) {
                    if (response.status === 200) {
                        $scope.showConfirmationLabel = true;
                        $scope.linkAccountByCardMessage = $translate.instant('link_card.link_account_by_card.successful');
                        $window.location.href = '#/dashboard';
                    }
                    else if (response.status === 400) {
                        $scope.showConfirmationLabel = true;
                        $scope.linkAccountByCardMessage = $translate.instant('link_card.link_account_by_card.unsuccessful');
                    }
                });
            };

            $scope.showPreStep = function() {
                $window.location.href = '#/link-card-pre-step';
            }
        }]);


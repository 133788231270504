const component = {
  template: `
    <div id="coop-data-confirmation" class="partnership-view">
      <div class="wrapper">
        <h1 translate="coop.data.header"></h1>
        <p translate="coop.data.description.link"></p>
        <p translate="coop.data.description.join"></p>
        <button class="button-solid" 
                ng-click="$ctrl.onProceed()" 
                translate="coop.data.proceed"></button>
      </div>
    </div>
  `,
  bindings: {},
  controller: CoopDataConfirmationController
};

angular
  .module('extra2App')
  .component('coopDataConfirmation', component);

function CoopDataConfirmationController($window, $location, $translatePartialLoader, $translate) {

  this.onProceed = onProceed;

  const redirectURL = $location.search()['redirectURL'];

  $translatePartialLoader.addPart('coop');
  $translate.refresh();

  function onProceed() {
    $window.location.href = redirectURL;
  }

}

angular.module('extra2App')
    .controller('CommunicationPreferencesController', ['$rootScope', '$scope', '$window', '$translatePartialLoader', '$translate', 'restservice', 'page', 'notificationservice',
        function ($rootScope, $scope, $window, $translatePartialLoader, $translate, restservice, page, notificationservice) {

            $translatePartialLoader.addPart('profile');
            $translate.refresh();

            page.setTitle($translate.instant('profile.communication_preferences.page_title'));

            restservice.getCommunicationPreferences().then(function (result) {
                updateCommunicationPreferences(result.data)
            });

            function updateCommunicationPreferences(memberProfile) {
                $scope.emailEnabled = memberProfile.emailEnabled;
                $scope.emailAddress = memberProfile.emailAddress;
                $scope.smsEnabled = memberProfile.smsEnabled;
                $scope.mobileNumber = memberProfile.mobileNumber;
                $scope.pushEnabled = memberProfile.pushEnabled;
                $scope.surveyEnabled = memberProfile.surveyEnabled;
                $scope.updateSurveysStatus();
            }
            
            $scope.hideConfirmationLabel = function () {
               $rootScope.$broadcast('ui-notification', { isActive:false });
            };

            $scope.showProfile = function() {
                $window.location.href = '#/profile';    
            };

            $scope.updateSurveysStatus = function() {
               $scope.hideConfirmationLabel();
            };

            $scope.setCommunicationPreferences = function (email, sms, push, surveys) {
               $scope.communicationPreferencesForm.$setPristine();
               restservice.setCommunicationPreferences(email, sms, push, surveys).then(function(response) {
                  if (response.status === 200) {
                     notificationservice.setNotification('profile.communication_preferences.preferences_saved', 'info');
                     updateCommunicationPreferences(response.data);
                  } else if (response.status === 202) {
                     notificationservice.setNotification('profile.communication_preferences.warning.deferred_saving', 'warning');
                     $scope.showProfile();
                  }
               })
            };

      }]);
angular.module('extra2App')
   .controller('LinkCardFailureController', ['$scope', '$translatePartialLoader', '$translate', 'cardservice', 
   function ($scope, $translatePartialLoader, $translate, cardservice) {
   		
        $translatePartialLoader.addPart('linkcard');
        $translate.refresh();

        $scope.linkCard = function() {
            cardservice.initLinkCard();
        }
   }])


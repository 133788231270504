angular.module('extra2App')
  .controller('AgreementDescriptionController', ['$scope', '$route', '$window', '$location', '$translatePartialLoader', '$translate', 'restservice', 'agreementservice', 'notificationservice', 'page', '$cookies',
    function($scope, $route, $window, $location, $translatePartialLoader, $translate, restservice, agreementservice, notificationservice, page, $cookies) {

      $translatePartialLoader.addPart('profile');
      $translate.refresh();
      $scope.agreement = JSON.parse(localStorage.getItem("selectedAgreement"));

        page.setTitle($translate.instant('profile.company_agreement_code.page_title'));
      
      var partnerInfoId;

      if($cookies.get('PART_AGREE')){
        partnerInfoId = JSON.parse($cookies.get('PART_AGREE'))['partnerInfoId'];
        var agreementCodeValue = JSON.parse($cookies.get('PART_AGREE'))['agreement_code'];
        if ($scope.agreement === null || $scope.agreement === undefined) {
            restservice.updateAgreementCode(agreementCodeValue, partnerInfoId).then(function (response) {
                if (response.status === 403) {
                    localStorage.setItem("selectedAgreement", JSON.stringify(response.data));
                    $scope.agreement = response.data;
                }
            });
        }
      }

      $scope.agreement_description = $scope.agreement.description;
      $scope.label = $scope.agreement.tc === "" || $scope.agreement.tc === undefined ? "profile.company_agreement_code_add_agreement" : "extra2ui.next"
      $scope.interactionTimeout = true;

      $scope.acceptAgreementCode = function() {
        if ($scope.agreement.tc === "" || $scope.agreement.tc === undefined) {
          if (confirm($translate.instant('profile.company_agreement_code.next_day_active_dialog'))) {
            if($scope.agreement.hasActiveAgreement === true){
              if (confirm($translate.instant('profile.company_agreement_code.replace_dialog'))) {
                $scope.callAgreementRestservice($scope.agreement);
              }
            }
            else {
              $scope.callAgreementRestservice($scope.agreement);
            }
          }
        } else {
          agreementservice.setShowHideTcButton(true);
          $window.location.href = '#/terms-and-conditions';
        }
      };

      $scope.showProfile = function() {
          restservice.redirectWhenDecline();
      };



      $scope.callAgreementRestservice = function(agreement){
        restservice.acceptAgreementCode(agreement, partnerInfoId).then(function(response) {
          if (response.status === 200) {
            $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.exists');
            $scope.agreementCodeValue = agreement.agreementCode;
            $scope.agreementCodeExists = true;

            //redirect user to profile after agreement is successfully added.
            $scope.showProfile();
          } else if(response.status === 400 || response.status === 403 || response.status === 412) {
            $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.bad_code');
          } else if (response.status === 429) {
              notificationservice.setNotification('profile.company_agreement_code.warn_timeout', 'warning');
          }
        });
      }
    }
  ]);

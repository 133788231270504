'use strict';

angular.module('extra2App').directive('ckUserInfo', ['restservice', 'localizationservice', '$route', function (restservice, localizationservice, $route) {
    return {
        templateUrl: 'views/directives/user-info.html',
        restrict: 'E',
        scope: { visible: '@'},
        link: function (scope) {

            scope.userInfo = {};
            scope.userInfo.displayName = "";

            restservice.getAdminRole().then(function(adminRole) {
                scope.userInfo.isAdmin = adminRole === "admin" || adminRole === "superAdmin";
            });

            restservice.getCommunicationPreferences().then(function(result) {

                var memberProfile = result.data;
                scope.userInfo.displayName = memberProfile.firstName + " " + memberProfile.lastName;
            })
        }
    };
}]);
'use strict';

angular.module('extra2App')
    .directive('ckValueMonthGraph', ['$translatePartialLoader', '$translate', 'arraycalcservice', 'localizationservice',
        function ($translatePartialLoader, $translate, arraycalcservice,localizationservice) {
            return {
                templateUrl: 'views/directives/valuemonthgraph.html',
                restrict: 'E',
                scope: { graphData: '='},
                link: function (scope, element) {

                    $translatePartialLoader.addPart('monthvaluegraph');
                    $translate.refresh();


                    var valueData = [];

                    scope.blocks = [];
                    scope.lines = [];
                    scope.lineWidth = 1;
                    scope.selectedMonth;

                    scope.$watch('graphData', function() {
                        if (scope.graphData && scope.graphData.valueData) {

                            scope.graphData.valueData.forEach(function(fd) {
                                valueData.push({
                                    value: fd.value,
                                    origValue: fd.value,
                                    year: fd.year,
                                    month: fd.month
                                });
                            });

                            // start with current month as selected
                            scope.graphData.selectedMonth = scope.graphData.currentMonth;
                            scope.printBlockValues = scope.graphData.printBlockValues;

                            CreateGraph(valueData,
                                300,
                                100,
                                scope.graphData.currentYear,
                                scope.graphData.currentMonth,
                                scope.graphData.printBlockValues
                            );
                        }

                    });

                    function CreateGraph(valueData, xTotalDimension, yTotalDimension, currentYear, currentMonth, printBlockValues) {

                        if (!valueData || valueData.length < 1)
                            return;

                        var totalHeight = yTotalDimension;
                        var topAreaHeight = Math.round(yTotalDimension * 0.10);
                        var legendAreaHeight = Math.round(yTotalDimension * 0.15);

                        var xDimension = xTotalDimension;
                        var yDimension = yTotalDimension - topAreaHeight - legendAreaHeight;

                        scope.lineWidth = Math.ceil(yTotalDimension / 100);

                        var lastYear = currentYear - 1;

                        // Setup step size and block width to make sure we fill out the whole
                        // SVG with blocks + space in between.
                        var xStep = Math.ceil(xDimension / 12);
                        var blockWidth = Math.ceil(xStep * 0.85);
                        xStep += (xStep * 0.15)/ 12;

                        // Let's normalize all values to the y dimension
                        var tempNormalizeArray = [];

                        // Get all values into a simple numbers array
                        valueData.forEach(function(fData){
                            tempNormalizeArray.push(fData.value);
                        });

                        // normalize it using y dimension as max value and 0 as min
                        tempNormalizeArray = arraycalcservice.normalizeNumberArray(tempNormalizeArray, yDimension, true);

                        // Update the value data with the normalized values
                        tempNormalizeArray.forEach(function(value, index){
                            valueData[index].origValue = valueData[index].value;
                            valueData[index].value = value;
                        });

                        var lastYearData = valueData.filter(function(fData) { return fData.year == lastYear; });
                        var lastYearDataFirstMonth = lastYearData.length > 0 ? parseInt(lastYearData[0].month) : 0;
                        var currentYearData = valueData.filter(function(fData) { return fData.year == currentYear; });
                        var currentYearDataFirstMonth = currentYearData.length > 0 ? parseInt(currentYearData[0].month) : 0;


                        // Current year block diagram
                        var currentYearValueDataPoints = [];
                        var currentMonthIndex = -1;

                        currentYearData.forEach(function(valueOfMonth, index) {

                            if (valueOfMonth.month == currentMonth)
                                currentMonthIndex = index;

                            currentYearValueDataPoints.push({ month: valueOfMonth.month, year: valueOfMonth.year, value: valueOfMonth.value, origValue: valueOfMonth.origValue });
                        })

                        // Add x/y values with first month offset and inverted values (y) for correct SVG coordinates
                        var blockValuePoints = [];

                            currentYearValueDataPoints.forEach(function (dataPoint, index) {
                            
                            dataPoint.value = dataPoint.value == 0 ? dataPoint.value + 1 : dataPoint.value;

                            var xVal = (index + (currentYearDataFirstMonth - 1)) * xStep;
                            var yVal = (yDimension + topAreaHeight) - (dataPoint.value);

                            scope.blocks.push({
                                month: dataPoint.month,
                                year: dataPoint.year,
                                value: dataPoint.origValue,
                                x: xVal,
                                y: yVal,
                                width: blockWidth,
                                height: yDimension + topAreaHeight - yVal,
                                text: {
                                    x: xVal + (blockWidth / 2),
                                    y: yVal - 5,
                                    text: Math.floor(dataPoint.origValue)
                                }
                            });
                        });

                        // Last year's curve line

                        // Add x/y values with first month offset and inverted values (y) for correct SVG coordinates
                        var value2dPoints = [];

                        // To ignore all leading zeros we use this little boolean flag
                        var foundData = false;
                        var firstIndex = 0;

                        lastYearData.forEach(function(valueOfMonth, index) {

                            var dataPoint = valueOfMonth.value;

                            if (!foundData && dataPoint > 0) {
                                foundData = true;
                                firstIndex = index;
                            }
                            if (foundData) {
                                var xVal = (index + (lastYearDataFirstMonth - 1)) * xStep + (blockWidth / 2);
                                // We begin the line at the left side of the block
                                if (index == firstIndex)
                                    xVal -= (blockWidth / 2);

                                // We end the line att the right side of the block or graph, depending on if it is december or not
                                if (index == lastYearData.length - 1) {
                                    xVal = valueOfMonth.month == 12 ? xDimension : xVal + (blockWidth / 2);
                                }

                                value2dPoints.push(xVal);
                                value2dPoints.push((yDimension + topAreaHeight) - (dataPoint));

                            }
                        })

                        // Let's handle the edge case where there is only a data point for dec,
                        // we will add one more datapoint before to be able to draw a line at all
                        if (value2dPoints.length == 2) {
                            value2dPoints.push(value2dPoints[0]);
                            value2dPoints.push(value2dPoints[1]);
                            value2dPoints[0] -= xStep;
                        }

                        // set the last year text position
                        var textLabelXPos = value2dPoints[value2dPoints.length - 2];
                        var textLabelYPos = value2dPoints[value2dPoints.length - 1] - Math.round(yDimension / 20);


                        // create smooth curve from 2d data points
                        var curvePoints = arraycalcservice.calculateCurvePoints(value2dPoints, 0.5, false, 24, 0, yDimension + topAreaHeight);

                        for(var i=0;i<curvePoints.length-2;i+=2) {
                            scope.lines.push({
                                x1: curvePoints[i],
                                y1: curvePoints[i+1],
                                x2: curvePoints[i+2],
                                y2: curvePoints[i+3]
                            })
                        }

                        // Write last year text and the month legend

                        scope.legendTexts = [];

                        scope.legendTexts.push({
                            x: textLabelXPos,
                            y: textLabelYPos,
                            cssClass: "year",
                            textAnchor: "end",
                            text: lastYear
                        });

                        scope.legendTexts.push({
                            x: (blockWidth / 2),
                            y: totalHeight,
                            cssClass: "legend",
                            textAnchor: "",
                            text: getLocalMonthText(1)
                        });

                        if (currentMonth != 1) {
                            scope.legendTexts.push({
                                x: (currentMonth - 1) * xStep + (blockWidth / 2),
                                y: totalHeight,
                                cssClass: "legend",
                                textAnchor: "",
                                text: getLocalMonthText(currentMonth)
                            });
                        }

                    }

                    function getLocalMonthText(month) {
                        return localizationservice.getLocalMonthShortUppercase(month);
                    }

                    scope.graphData.selectMonth = function(month, year) {

                        scope.graphData.selectedMonth = year == scope.graphData.currentYear ? Number(month) : 0;

                        var foundValueData = valueData.filter(function(d) { return d.year == year && d.month == month; });

                        scope.graphData.selectedValue = foundValueData.length > 0 ? Number(foundValueData[0].origValue).toFixed(0) : 0;

                    }

                }
            }
        }])
  


'use strict';

angular.module('extra2App').directive('ckDashboardContainer', function($compile, heromoduleservice) {
    return {
        restrict: 'E',
        scope: { modules: '='},
        link: function(scope, element, attrs) {

            var moduleTypeToDirectiveMap = {
                "rolling_models": "ck-rollingmodels",
                "awards": "ck-awards",
                "fuel_consumption": "ck-fuel",
                "points": "ck-points",
                "vouchers": "ck-vouchers",
                "banners": "ck-banners",
                "tiers": "ck-tiers"
                //"webshop": "ck-webshop" // TODO verify
            };

            heromoduleservice.setHero('');

            var createModuleElement = function(directiveName, module, index) {
                var moduleElement = angular.element(document.createElement(directiveName));
                moduleElement.attr("module", "modules[" + index + "]");
                return $compile(moduleElement)(scope);
            };

            scope.$watch('modules', function() {
                localStorage.setItem("pointsExist", false);
                if (scope.modules) {
                    scope.modules.forEach(function(module, index) {
                        var moduleName = moduleTypeToDirectiveMap[module[0]];
                        if (moduleName) {

                            var moduleElement = createModuleElement(moduleName, module, index);
                            element.append(moduleElement);
                            heromoduleservice.setHero('ck-points');
                            if (moduleName === 'ck-points') {
                                localStorage.setItem("pointsModule", JSON.stringify(module[1]));
                                localStorage.setItem("pointsExist", true);
                            }
                        }
                    });
                }
            });

        }
    }
});

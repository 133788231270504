angular.module('extra2App')
   .controller('PrivacyPolicyController', ['$scope', '$sce', 'page', 'restservice', '$translatePartialLoader', '$translate',
      function ($scope, $sce, page, restservice, $translatePartialLoader, $translate) {

         $translatePartialLoader.addPart('profile');
         $translate.refresh();

         page.setTitle($translate.instant('profile.privacy_policy.page_title'));

         var countryCode = localStorage.getItem("businessUnit");
         var videoURL = 'https://www.youtube.com/embed/';
         var videoPlayerParams = '?controls=1&fs=1&modestbranding=1&rel=0';

         restservice.getSettings().then(function (result) {
            restservice.getModuleData(result.apiUnresURI + result.privacyPolicyUrl + countryCode).then(function (response) {
               $scope.videoID = response.data.videoID;
               $scope.video = $sce.trustAsResourceUrl(videoURL + $scope.videoID + videoPlayerParams);
               $scope.text = $sce.trustAsHtml(response.data.text);
            });
         });

         $scope.back = function() {
            page.back();
         }
   }]);

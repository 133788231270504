const component = {
  template: `
    <div id="mastercard-landingpage" class="partnership-view" >
      <div class="wrapper mc-wrapper">
        <p>
          <img src="/images/mastercard/banner_logo.jpg" style="width: 100%">
          <img src="/images/mastercard/car.jpg" style="width: 100%">
        </p>
        <div class="mc-text-wrapper">
          <div class="mc-text-header">
            {{ 'mastercard.text.header.landing' | translate }}
          </div>
          <div class="mc-text-content">
            {{ 'mastercard.text.content.landing' | translate }}
          </div>
          <button class="button-solid ng-scope mc-text-button-red" ng-click="$ctrl.onProceed()" translate="mastercard.signin"></button>
          <button class="button-outline ng-scope mc-text-button-white" ng-click="$ctrl.onProceed()" translate="mastercard.apply"></button>
        </div>
      </div>
    </div>
  `,
  bindings: {},
  controller: LandingPageController
};

angular
  .module('extra2App')
  .component('mastercard', component);

function LandingPageController($window, $location, $translatePartialLoader, $translate, $cookies) {

  this.onProceed = onProceed;

  const queryString = $window.location.search;
  $location.search(queryString.substring(1, queryString.length))

  const redirectURL = $location.search()['redirectURL'];

  $translatePartialLoader.addPart('mastercard');
  $translate.refresh();

  const redirectLang = $cookies.get('redirect-lang');
  if (redirectLang) {
    $translate.use(redirectLang);
    $translate.refresh();
  }

  function onProceed() {
    $window.location.href = redirectURL;
  }
}

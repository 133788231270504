'use strict';

angular.module('extra2App')
   .directive('ckDiscount', ['restservice', function (restservice) {
   return {
      templateUrl: 'views/directives/discount.html',
      restrict: 'E',
      scope: { module: '=' },
      link: function (scope) {
            
            scope.discounts = scope.module;
            
         }
      };
   }]);
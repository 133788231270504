'use strict';

angular.module('extra2App')
    .controller('TiersTransactionsController', ['$scope', '$translatePartialLoader', '$translate',  'tierstransactionservice', 'localizationservice', 'page', 'restservice',
        function ($scope, $translatePartialLoader, $translate, tierstransactionservice, localizationservice, page, restservice) {

            $translatePartialLoader.addPart('extra2ui');
            $translatePartialLoader.addPart('tier');
            $translate.refresh();

            page.setTitle($translate.instant('tiers_transactions.title'));

            // restservice.getTiersTransactions().then(function(result) {
            //     var transactions = result.data;
            //     $scope.transactions = transactions;
            //     console.log(transactions);
            //     $scope.purchases = transactions.purchases;
            //     console.log($scope.purchases);
            //     // if (paymentCardsResult.paymentCards.length < 1) {
            //     //     restservice.triggerInitiateLinkCard('dashboard');
            //     // }
            // });

            // // different browsers find either null or string "undefined":
            // if ((localStorage.getItem("fuel_value_data") != null) && localStorage.getItem("fuel_value_data") != "undefined") {
            //     var fuelValueData = JSON.parse(localStorage.getItem("fuel_value_data"));
            // }
            //
            /**
             * Create array with current month/year as index 0 and previous 23 months as index 1, 2, 3, etc.
             */
            var max = 24;
            var loadMonths = function() {
                var months = [];
                var date = new Date();
                // last 24 months is the maximum
                for (var i = 0; i < max; i++) {
                    var month = {};
                    month.index = i;
                    month.year = date.getFullYear();
                    month.month = date.getMonth() + 1;
                    month.monthText = localizationservice.getLocalMonthLongCapitalized(month.month);
                    if (i === 0) {
                        month.last = true;
                    }
                    else if (i === (max - 1)) {
                        month.first = true;
                    }
                    months[i] = month;
                    // Make sure to set day to 1, otherwise month "rolling" will not work properly
                    date.setDate(1);
                    date.setMonth(date.getMonth() - 1);
                }
                return months;
            };

            $scope.monthData = loadMonths();
            var selectedMonth = $scope.monthData[0];

            // $scope.fuelGraphData = {
            //     valueData: fuelValueData,
            //     currentYear: selectedMonth.year,
            //     currentMonth: selectedMonth.month,
            //     printBlockValues: true,
            //     unitLingoKey: "month_value_graph.unit.fuel",
            //     selectedMonth: selectedMonth
            // };
            //
            // $scope.transactionsGraphData = {
            //     valueData: [],
            //     currentYear: selectedMonth.year,
            //     currentMonth: selectedMonth.month,
            //     printBlockValues: true,
            //     unitLingoKey: "month_value_graph.unit.currency",
            //     selectedMonth: selectedMonth
            // };
            //
            // $scope.selectedGraph =  JSON.parse(localStorage.getItem("selected_graph"));

            var loadMonth = function(selectedMonth) {

                $scope.selectedMonth = selectedMonth;

                // if ($scope.fuelGraphData.selectMonth) {
                //     $scope.fuelGraphData.selectMonth($scope.selectedMonth.month, $scope.selectedMonth.year);
                // }
                loadPurchases($scope.selectedMonth);
            };

            loadMonth(selectedMonth);

            function loadPurchases(selectedMonth) {
                tierstransactionservice.loadPurchases(selectedMonth);
            }

        }]);
'use strict';

angular.module('extra2App')
  .controller('PointsHistoryController', ['$scope', '$window', '$translatePartialLoader', '$translate',  'transactionservice', 'localizationservice', 'restservice', 'page',
   function ($scope, $window, $translatePartialLoader, $translate, transactionservice, localizationservice, restservice, page) {

      $translatePartialLoader.addPart('points');
      $translate.refresh();

       page.setTitle($translate.instant('points.title'));

       $scope.iconsBasePath = restservice.getIconsBasePath();
       $scope.pointsModule = JSON.parse(localStorage.getItem("pointsModule"));
       $scope.showMasterCardLoginButton = false;

       $scope.expirationsPresent = $scope.pointsModule.values.length > 0;

       for (var expirationDate in $scope.pointsModule.values) {
           var dateObj = new Date($scope.pointsModule.values[expirationDate].expirationDate);
           var month = dateObj.getMonth() + 1;
           $scope.pointsModule.values[expirationDate].expirationDate =
               localizationservice.getLocalMonthLongCapitalized(month);

           $scope.pointsModule.values[expirationDate].expirationDate =
               $scope.pointsModule.values[expirationDate].expirationDate
               + ' ' + dateObj.getDate()
               + ' ' + dateObj.getFullYear();
       }

       restservice.getPaymentCards().then(function(result) {
           var paymentCardsResult = result.data;

           $scope.showMasterCardLoginButton = paymentCardsResult.hasCMC;
       });

       /**
         * Create array with current month/year as index 0 and previous 23 months as index 1, 2, 3, etc.
         */
        var max = 24;
        var loadMonths = function() {
        	var months = [];
        	var date = new Date();
        	// last 24 months is the maximum
        	for (var i = 0; i < max; i++) {
        		var month = {};
        		month.index = i;
        		month.year = date.getFullYear();
        		month.month = date.getMonth() + 1;
        		month.monthText = localizationservice.getLocalMonthLongCapitalized(month.month);
        		if (i == 0) {
        			month.last = true;
        		}
        		else if (i == (max - 1)) {
        			month.first = true;
        		}
        		months[i] = month;
                // Make sure to set day to 1, otherwise month "rolling" will not work properly
                date.setDate(1);
        		date.setMonth(date.getMonth() - 1);
        	}
        	return months;
        };

        $scope.monthData = loadMonths();
        var selectedMonth = $scope.monthData[0];

        $scope.fuelGraphData = {
            valueData: $scope.currentPoints,
            currentYear: selectedMonth.year,
            currentMonth: selectedMonth.month,
            printBlockValues: true, 
            unitLingoKey: "month_value_graph.unit.fuel",
            selectedMonth: selectedMonth
        };

        $scope.transactionsGraphData = {
            valueData: [],
            currentYear: selectedMonth.year,
            currentMonth: selectedMonth.month,
            printBlockValues: true, 
            unitLingoKey: "month_value_graph.unit.currency",
            selectedMonth: selectedMonth
        };

      $scope.selectedGraph = "fuel";

        var loadMonth = function(selectedMonth) {

            $scope.selectedMonth = selectedMonth;

           if ($scope.fuelGraphData.selectMonth) {
               $scope.fuelGraphData.selectMonth($scope.selectedMonth.month, $scope.selectedMonth.year);
           }
           loadPoints($scope.selectedMonth);
       }

        loadMonth(selectedMonth);


      $scope.goNext = function() {
            var monthIndex = $scope.selectedMonth.index - 1;

            if (monthIndex > -1) {
            	var month = $scope.monthData[monthIndex];
            	loadMonth(month);
         }
        }

      $scope.goBack = function() {
            var monthIndex = $scope.selectedMonth.index + 1;

            if (monthIndex < $scope.monthData.length) {
            	var month = $scope.monthData[monthIndex];
            	loadMonth(month);
         }
        }

      function loadPoints(selectedMonth) {
         transactionservice.loadPoints(selectedMonth);
      }

       $scope.openMasterCardLoginUrl = function() {
           $translate('extra2ui.mastercard_login.link_path').then(function (translation) {
               $window.open(translation);
           });
       }

  }]);
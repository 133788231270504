'use strict';

angular.module('extra2App').factory('versionservice', ['restservice', function(restservice){
   var version = {
        version: ''
    };

    return {
        getVersion: function () {
            return verision.version;
        }
    };

}]);

'use strict';

angular.module('extra2App')
    .directive('ckFuel', ['$location', 'localizationservice', 'restservice', 'page', '$translatePartialLoader', '$translate', function ($location, localizationservice, restservice, page, $translatePartialLoader, $translate) {
        return {
            templateUrl: 'views/directives/fuel.html',
            restrict: 'E',
            scope: { module: '=' },
            link: function (scope) {
                restservice.getToggle('POINTS_SUMMARY').then(function(toggleResponse) {
                    if (!toggleResponse.data || localStorage.getItem("businessUnit") != 'PL') {
                        scope.showFuelDiscount = true;
                    }
                });

                scope.fuelData = scope.module[1];

                $translatePartialLoader.addPart('fuelconsumption');
                $translate.refresh();

                var currentDate = new Date();

                var currentYear = currentDate.getFullYear();
                var currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0 based number, we need jan to be 1.\

                scope.currentFuelData = {
                    month: localizationservice.getLocalMonthLongCapitalized(currentMonth),
                    year: currentYear,
                    quantity: 0
                };

                if (scope.fuelData.values) {

                    var filterCurrentResult = scope.fuelData.values.filter(function(fData) { return fData.year == currentYear && fData.month == currentMonth; });

                    scope.currentFuelData.quantity = filterCurrentResult.length > 0 ? Math.round(filterCurrentResult[0].quantity) : 0;

                    var valueData = [];

                    scope.fuelData.values.forEach(function(fuel){
                        valueData.push({
                            value: fuel.quantity,
                            year: fuel.year,
                            month: fuel.month
                        });
                    });

                    scope.graphData = {
                        valueData: valueData,
                        currentYear: currentYear,
                        currentMonth: currentMonth,
                        printBlockValues: false,
                        unit: "l"
                    };

                    localStorage.setItem("fuel_value_data", JSON.stringify(scope.graphData.valueData));
                }

                scope.goToMyTransactions = function(val) {
                    localStorage.setItem("selected_graph", JSON.stringify(val));
                    $location.path('/my-transactions');
                }
            }
        }
    }]);

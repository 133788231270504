'use strict';

angular.module('extra2App')
   .directive('ckBackIcon', [function () {
	return {
		templateUrl: 'views/directives/icons/back.html',
		templateNamespace: 'svg',
		restrict: 'E',
		replace: true
	};
}]);
'use strict';

angular.module('extra2App')
   .directive('ckFooter', ['$window', 'page', '$translatePartialLoader', '$translate', '$location', function ($window, page, $translatePartialLoader, $translate, $location) {
   return {
      templateUrl: 'views/directives/footer.html',
      restrict: 'E',
      scope: { module: '=' },
      link: function (scope) {
         $translatePartialLoader.addPart('extra2ui');
         $translate.refresh();

         scope.showPrivacyPolicy = function() {
            page.gotoView('privacy-policy');
         };

         scope.showCustomerService = function() {
            $window.open($translate.instant('extra2ui.URL_customer_service'), "_blank");
         };

         scope.currentYear = new Date().getFullYear();

         scope.mastercardPath = !!$location.path().match('mastercard');
      }
   }
}]);
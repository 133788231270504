angular.module('extra2App')
  .controller('AgreementCodeController', ['$scope', '$window', '$location', '$timeout', '$translatePartialLoader', '$translate', 'restservice', 'page', 'agreementservice',
    function($scope, $window, $location, $timeout, $translatePartialLoader, $translate, restservice, page, agreementservice) {

      $translatePartialLoader.addPart('profile');
      $translate.refresh();
      $scope.agreement = JSON.parse(localStorage.getItem("selectedAgreement"));

      page.setTitle($translate.instant('profile.company_agreement_code.page_title'));

      restservice.getMemberAgreementCode($scope.agreement.agreementCode).then(function(result) {
        $scope.memberAgreement = result.data;
        var agreementCodeValue = $scope.memberAgreement.title;
        if (agreementCodeValue) {
          $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.exists');
          $scope.agreementCodeValue = agreementCodeValue;
          $scope.agreementCodeDescription = $scope.memberAgreement.description;
          $scope.agreementCodeExists = true;
          $scope.defaultForBU = $scope.memberAgreement.defaultForBU;
          $scope.tc = $scope.memberAgreement.tc;
        } else {
          $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.missing');
          $scope.agreementCodeValue = null;
          $scope.agreementCodeExists = false;
        }

      });

      $scope.showConfirmationLabel = false;

      $scope.agreementCodeTooLong = $translate.instant('profile.company_agreement_code_too_long');
      $scope.agreementCodeInvalid = $translate.instant('profile.company_agreement_code_invalid');

      $scope.updateAgreementCode = function(agreementCodeValue) {

        restservice.updateAgreementCode(agreementCodeValue).then(function(response) {
          if (response.status === 200) {
            $scope.showConfirmationLabel = true;
            $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.exists');
            $scope.agreementCodeValue = agreementCodeValue;
            $scope.agreementCodeExists = true;
          } else if (response.status === 400) {
            $scope.showConfirmationLabel = false;
            $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.bad_code');
          }
        });
      };

      $scope.deleteAgreementCode = function() {
        restservice.deleteAgreementCode().then(function(response) {
          if (response.status === 200) {
              localStorage.removeItem("selectedAgreement");
              $scope.agreementCodeValue = null;
              $scope.agreementCodeExists = false;
              $timeout($scope.showProfile, 600);
          }
        });
      };

      $scope.showProfile = function() {
        page.gotoView(page.profileView);
      };

      $scope.showTC = function() {
        agreementservice.setShowHideTcButton(false);
        localStorage.setItem("selectedAgreement", JSON.stringify($scope.memberAgreement));
        page.gotoView('terms-and-conditions');
      }
    }
  ]);

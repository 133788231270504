'use strict';

angular.module('extra2App')
   .directive('ckAdminBanners', ['$location','restservice', 'page', 'localizationservice', '$route', '$translate', 'httpPostFactory',
      function ($location, restservice, page, localizationservice, $route, $translate, httpPostFactory) {
         return {
            templateUrl: 'views/directives/admin-banners.html',
            restrict: 'E',
            scope: { module: '=' },
            link: function (scope, element, attributes) {

               scope.showAddBannerView = false;
               scope.isEdit = false;
               scope.createBanner = {};
               scope.title = "";
               var businessUnit = localStorage.getItem("businessUnit");

               var setHeader = function(id) {
                  scope.title = $translate.instant(id ? 'admin.banners_edit' : 'admin.banners_add');
                  if (id) {
                     scope.title += " '" + id.productGroupId + "'";
                  }
               };

               var addBanner = function() {
                  scope.showAddBannerView = true;
               };

               var editBanner = function(id) {
                  scope.createBanner = id;
                  scope.createBanner.activationDate = new Date(scope.createBanner.activationDate);
                  scope.createBanner.expirationDate = new Date(scope.createBanner.expirationDate);
                  scope.showAddBannerView = true;
               };

               var reloadBanners = function(response) {
                  if(response.status === 200){
                     $route.reload();
                  }
               };

               restservice.getSettings().then(function (result) {
                  restservice.getModuleData(result.apiAdminURI + result.adminContentsUri + "banners/" + businessUnit).then(function (response) {
                     if (response.status === 200) {
                        for (var item in response.data) {
                           var obj = response.data[item];
                           if(obj.contentType === 'BANNERS'){
                              scope.bannerData = obj.value;
                           }
                        }
                     }
                  });
               });

               scope.$on('banners', function (event, data) {
                  scope.bannerData = data;
               });

               scope.$on('bu', function (event, data) {
                  businessUnit = data.name;
               });

               scope.submit = function() {
                  if (scope.isEdit) {
                     updateBanner();
                  } else {
                     scope.createBanner.countryCode = businessUnit;
                     scope.createBanner.contentType = 'BANNERS';
                     scope.createBanner.altText = scope.createBanner.title;
                     restservice.uploadAdminContent(scope.createBanner).then(function (response) {
                        reloadBanners(response);
                     });
                  }
                  scope.isEdit = false;
               };

               var updateBanner = function() {
                  restservice.updateAdminContent(scope.createBanner).then(function (response) {
                     reloadBanners(response);
                  });
               };

               scope.openEditBannerView = function(id) {
                  if (id === undefined) {
                     addBanner();
                  } else {
                    scope.isEdit = true;
                     editBanner(id);
                  }
                  setHeader(id);
               };

               scope.isPublished = function(item) {
                  return($translate.instant(item.publish ? 'admin.item_published' : 'admin.item_unpublished'));
               };

               scope.deleteBanner = function(banner) {
                  if (confirm($translate.instant('admin.banners_confirm_delete'))) {
                     restservice.getSettings().then(function (result) {
                        restservice.deleteModuleData(result.deleteContent + businessUnit + "/" + banner.contentId).then(function (response) {
                           reloadBanners(response);
                        });
                     });
                  }
               };

               scope.discard = function() {
                  scope.createBanner = {};
                  scope.showAddBannerView = false;
                  scope.isEdit = false;
               };

               scope.getDate = function() {
                  var today = new Date();
                  var dd = today.getDate();
                  var mm = today.getMonth()+1;
                  var yyyy = today.getFullYear();

                  if(dd < 10) {
                     dd = '0'+ dd;
                  }

                  if(mm < 10) {
                     mm = '0'+ mm;
                  }

                  today = yyyy + '-' + mm + '-' + dd;
                  return(today);
               };
            }};
         }]);

angular.module('extra2App')
    .controller('DeniedTermsConditionsController', ['$window', '$scope', 'restservice', '$translatePartialLoader', '$translate', 'page', '$location',
        function ($window, $scope, restservice, $translatePartialLoader, $translate, page, $location) {

            $translatePartialLoader.addPart('denyaccess');
            $translatePartialLoader.addPart('profile');
            $translate.refresh();

            page.setTitle($translate.instant('profile.terms_conditions'));

            $scope.tryAgain = function () {
                $location.path('/');
            };
}]);

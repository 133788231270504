import {draftStatusToMessageKey} from "../dashboard/dashboard.service";

const component = {
  template: `
    <div id="mastercard-redirect" class="partnership-view" >
      <div class="wrapper mc-wrapper">
        <p>
          <img src="/images/mastercard/banner_logo.jpg" style="width: 100%">
          <img src="/images/mastercard/car.jpg" style="width: 100%">
        </p>
        <div class="mc-text-wrapper mc-text-wrapper-short">
          <div class="mc-text-header">
            {{ 'mastercard.text.header.redirect' | translate }}
          </div>
          <div class="mc-text-content">
            {{ 'mastercard.text.content.redirect' | translate }}
          </div>
          <button class="button-solid mc-text-button-red"
                ng-click="$ctrl.onProceed()" 
                ng-disabled="$ctrl.countryError"
                translate="mastercard.proceed"></button>
        </div>
      </div>
      <form id="seb" method="post">
        <input id="prefill" name="prefill" type="hidden">
      </form>
    </div>
  `,
  bindings: {},
  controller: RedirectController
};

angular
  .module('extra2App')
  .component('mastercardRedirect', component);

function RedirectController(
  $window,
  $location,
  $translatePartialLoader,
  $translate,
  $cookies,
  notificationservice,
  restservice
) {

  this.onProceed = onProceed;

  const error = $location.search()['error'];
  this.countryError = !!error && error === 'country';

  $translatePartialLoader.addPart('mastercard');
  $translate.refresh();

  if (this.countryError) {
    onCountryError();
  }

  function onCountryError() {
    notificationservice.setNotification('mastercard.error.country', 'warning');
  }

  function onProceed() {
    const partnerAgreement = $cookies.get('PARTNER_AGREEMENT');

    if (partnerAgreement) {
      const id = JSON.parse(partnerAgreement)['agreement_draft_id'];
      restservice.mastercardRedirect(id)
      .then(response => {
        $cookies.remove('PARTNER_AGREEMENT');
        $cookies.remove('MASTERCARD');

        if (response.status === 200) {
          const form = document.forms[0];
          form.action = response.data.location;
          form.prefill.value = response.data.prefill;
          form.submit();
        } else if (draftStatusToMessageKey.hasOwnProperty(response.status)) {
          notificationservice.setNotification(
              draftStatusToMessageKey[response.status], 'warning');
          $location.path("/dashboard");
        } else {
          $location.path('/global-failure-page');
        }
      })
    } else {
      $location.path('/dashboard');
    }
  }
}

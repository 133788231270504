angular.module('extra2App')
.controller('AccountActionController',
    ['$scope', '$rootScope', '$window', 'restservice', 'page', '$translatePartialLoader', '$translate', '$cookies',
      function ($scope, $rootScope, $window, restservice, page, $translatePartialLoader, $translate, $cookies) {

        $translatePartialLoader.addPart('profile');
        $translatePartialLoader.addPart('extra2ui');
        $translate.refresh();
        var user = null;
        var monthMap = {};
        var minimumBirthDate = new Date();

        restservice.getLoginStatus().then(function (result) {
            user = result.data;

            // Set 18 year for Polish member
            if(user.businessUnit === "PL") { // TODO move this logic to backend in task LOY-455
                minimumBirthDate.setFullYear(minimumBirthDate.getFullYear() - 18);
            } else {
                minimumBirthDate.setFullYear(minimumBirthDate.getFullYear() - 16);
            }
            minimumBirthDate = minimumBirthDate.getTime();
        });

        page.setTitle($translate.instant('profile.account_alert_header'));

        initialize();

        function initialize() {

          var date_of_birth = {};
          date_of_birth.years = [];
          date_of_birth.months = [];
          date_of_birth.days = [];

          $scope.date_of_birth = date_of_birth;

          getYears();
          getMonths();
          getDays();

          function getYears() {
            var myDate = new Date();
            var year = myDate.getFullYear();

            for (var i = 1900; i < year + 1; i++) {
              date_of_birth.years.push(i);
            }

            date_of_birth.years.reverse();
          }

          function getMonths() {
            for (var i = 1; i <= 12; i++) {
              var monthNo = (i < 10 ? '0' : '') + i;
              var monthName = $translate.instant('extra2ui.month_' + monthNo);
              monthMap[monthName] = monthNo;
              date_of_birth.months.push(monthName);
            }
          }

          function getDays() {
            for (var i = 1; i <= 31; i++) {
              date_of_birth.days.push((i < 10 ? '0' : '') + i);
            }
          }
        }

        $scope.updateBirthdate = function updateBirthdate() {
            var birthDate = $scope.year + "-" + monthMap[$scope.month] + "-" + $scope.day;
            var birthDateObj = new Date(birthDate);

            if (birthDateObj.getTime() <= minimumBirthDate) {
                restservice.updateBirthdate(birthDate).then(function (response) {
                    if (response.status === 200) {
                        var agreeCookie = $cookies.get('PART_AGREE');
                        if(agreeCookie){
                          $window.location.href = '#/agreement-description';
                        }
                        else {
                          // Go to dashboard to trigger any other required steps, like add card
                          $window.location.href = '#/dashboard';
                        }
                    }
                    else if (response.status === 400) {
                        $scope.accountMessage = $translate.instant('profile.account_failure');
                    }
                });
            }
            else {
                $scope.accountMessage = $translate.instant('profile.account_member_too_young');
            }
        };

    }]);

(function () {

  'use strict';

  const component = {
    template: `<a class="logout" ng-show="$ctrl.visible" ng-click="$ctrl.logout()" translate>extra2ui.logout</a>`,
    bindings: {},
    controller: ckLogoutButton,
    transclude: true
  }

  angular
    .module('extra2App')
    .component('ckLogoutButton', component);

  ckLogoutButton.$inject = ['$window', '$route', 'restservice', '$translatePartialLoader', '$translate', '$cookies', '$rootScope'];

  function ckLogoutButton($window, $route, restservice, $translatePartialLoader, $translate, $cookies, $rootScope) {

    const vm = this;
    vm.logout = logout;
    vm.visible = false;

    const logoutVisiblePaths = ['/profile', '/link-card-pre-step', '/denied-terms-and-conditions', '/account-action'];

    activate();

    function activate() {
      $rootScope.$on("$routeChangeSuccess", function () {
        setVisibility();
      });
      $translatePartialLoader.addPart('extra2ui');
      $translate.refresh();
      setVisibility();
    }

    function setVisibility() {
      const currentPath = $route.current.$$route.originalPath;
      vm.visible = !!logoutVisiblePaths.includes(currentPath);
    }

    function logout() {
      let partnerClientId;
      const partnerClientIdCookie = $cookies.get('PARTNER_CLIENT_ID');
      const agreeCookie = $cookies.get('PART_AGREE');

      if (partnerClientIdCookie) {
        partnerClientId = partnerClientIdCookie;
      }
      if (agreeCookie) {
        const agreementActivation = JSON.parse(agreeCookie);
        if (agreementActivation) {
          const partnerInfoId = agreementActivation['partnerInfoId'];
          restservice.getPartnerInfo(partnerInfoId).then(function (response) {
            if (response.status === 200) {
              const partnerInfo = response.data;
              partnerClientId = partnerInfo.partnerClientId;
              restservice.logout(partnerClientId);
            }
          });
        }
      } else {
        restservice.logout(partnerClientId);
      }
    }
  }

})();

const component = {
  template: `
      <div id="dashboard-container">
         <div id="spinner-container" ng-show="$ctrl.loading">
            <div id="spinner"></div>
         </div>
         <ck-dashboard-container modules="$ctrl.modules"></ck-dashboard-container>
      </div>
    `,
  bindings: {},
  controller: DashboardController
};

angular
  .module('extra2App')
  .component('dashboard', component);

function DashboardController(
  DashboardService,
  $timeout,
  restservice,
  page,
  versionservice,
  $translatePartialLoader,
  $translate,
  notificationservice
) {

  const vm = this;

  vm.modules = [];
  vm.loading = true;

  init();

  function init() {
    page.setLoading(true);
    $translatePartialLoader.addPart('extra2ui');
    $translate.refresh();
    page.setTitle($translate.instant('extra2ui.title'));
    printVersion();
    DashboardService.supportQueryParamAction();

    restservice.getLoginStatus()
      .then(handleLoginStatus);

    restservice.memberNotified()
      .then(handleMemberNotified);
  }

  function handleLoginStatus(loginStatus) {
    const warnings = loginStatus.data.warnings;
    if (warnings.length) {
      DashboardService.supportWarnings(warnings);
    } else {
      DashboardService.supportPartnerStrategies();
    }

    restservice.getDashboardModules()
      .then(setDashboardModules);
  }

  function setDashboardModules(result) {
    vm.modules = !!result ? Object.entries(result.data) : [];
    vm.loading = false;
    page.setLoading(false);
  }

  function printVersion() {
    restservice.getVersion().then(function (result) {
      const appVersion = result.data;
      console.log('appVersion : ' + appVersion.version);
    });
  }

  function notifyMember() {
    restservice.memberNotified(!vm.notify);
  }

  function handleMemberNotified(response) {
    if (response.status === 204) {
      notificationservice.setNotification('extra2ui.continue_as_extra_member', 'info', notifyMember);
    }
  }

}

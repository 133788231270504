'use strict';

angular.module('extra2App').factory('cardservice', ['$location', '$window', 'restservice', function($location, $window, restservice){

    var getCardChoices = function() {
        return restservice.getCardChoices();
    };

	var initLinkCard = function(originPage, prefix) {
        restservice.triggerInitiateLinkCard(originPage, prefix);
    };

	return {
        initLinkCard: initLinkCard,
        getCardChoices: getCardChoices
	};
}]);

(function () {

  'use strict';

  angular
    .module('extra2App')
    .config(Config);
  
  function Config($locationProvider, $translateProvider, $httpProvider) {

    $httpProvider.interceptors.push('APIInterceptor');
    $locationProvider.hashPrefix('');

    // Need to disable this until we figure out how to do URL rewrites
    // $locationProvider.html5Mode(true);

    if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
    }

    //disable IE ajax request caching
    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    // extra
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

    $translateProvider.useStorage('languagestorageservice');
    $translateProvider.storageKey('lang');

    // $translateProvider.preferredLanguage('en');
    $translateProvider.registerAvailableLanguageKeys(['en', 'da', 'no', 'nb', 'sv', 'et', 'lt', 'lv', 'pl', 'ru'], {
      'en-*': 'en',
      'da-*': 'da',
      'no-*': 'nb',
      'sv-*': 'sv',
      'et-*': 'et',
      'lt-*': 'lt',
      'lv-*': 'lv',
      'pl-*': 'pl',
      'ru-*': 'ru'
    });

    $translateProvider.determinePreferredLanguage();
    $translateProvider.fallbackLanguage('en');
    $translateProvider.useSanitizeValueStrategy('escape');
    // languagestorageservice.setLanguageFromBrowser($translateProvider.preferredLanguage());

    $translateProvider.useLoader('$translatePartialLoader', {
      urlTemplate: 'data/i18n/{lang}/{part}.json'
    });
  }
})();
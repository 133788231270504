angular.module('extra2App')
   .controller('LoginController', ['$scope', '$window','restservice', function ($scope, $window, restservice) {

      var circleKidAuthorizeUrl = "";
      var additionalParams = "";

	  restservice.getSettings().then(function(settings) {
          circleKidAuthorizeUrl = settings.circleKidAuthorizeUrl;
          additionalParams = settings.loginState;
	  }).then(function() {
	    console.log(circleKidAuthorizeUrl);
          if (circleKidAuthorizeUrl !== "")
              $window.location.href = circleKidAuthorizeUrl + additionalParams;
      });

   }])


const component = {
  template: `
    <div id="coop-agreement-confirmation" class="partnership-view">
      <div class="wrapper">
        <h1 translate="coop.confirmation.header"></h1>
        <p translate="coop.confirmation.description"></p>
        <button class="button-solid"
                ng-click="$ctrl.onProceed()" 
                ng-disabled="$ctrl.countryError"
                translate="coop.confirmation.proceed"></button>
      </div>
    </div>
  `,
  bindings: {},
  controller: CoopAgreementConfirmationController
};

angular
  .module('extra2App')
  .component('coopAgreementConfirmation', component);

function CoopAgreementConfirmationController($location, $translatePartialLoader, $translate, restservice) {

  this.onProceed = onProceed;

  $translatePartialLoader.addPart('coop');
  $translate.refresh();

  function onProceed() {
    const id = JSON.parse($cookies.get('PARTNER_AGREEMENT'))['agreement_draft_id'];
    restservice.coopAgreementConfirmation(id)
      .then(response => {
        if (response.status === 200) {
          $location.path('/dashboard');
        } else {
          $location.path('global-failure-page'); // TODO smarter error support
        }
      });
  }
}

angular.module('extra2App')
  .controller('AddAgreementCodeController', ['$scope', '$window', '$location', '$translatePartialLoader', '$translate', 'restservice', 'agreementservice', 'notificationservice', 'page', '$timeout',
    function($scope, $window, $location, $translatePartialLoader, $translate, restservice, agreementservice, notificationservice, page, $timeout) {

      $translatePartialLoader.addPart('profile');
      $translate.refresh();
      $scope.showTC = false;

      page.setTitle($translate.instant('profile.company_agreement_code.page_title'));

      $scope.showConfirmationLabel = false;

      $scope.agreementCodeTooLong = $translate.instant('profile.company_agreement_code_too_long');
      $scope.agreementCodeInvalid = $translate.instant('profile.company_agreement_code_invalid');

      $scope.updateAgreementCode = function(agreementCodeValue) {

         restservice.updateAgreementCode(agreementCodeValue).then(function(response) {
            if (response.status === 200) {
               $scope.showConfirmationLabel = true;
               $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.exists');
               $scope.agreementCodeValue = agreementCodeValue;
               $scope.agreementCodeExists = true;
            } else if (response.status === 404 || response.status === 400 || response.status === 412) {
               $scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.bad_code');
            } else if (response.status === 403) {
               agreementservice.setAgreement(response.data);
               localStorage.setItem("selectedAgreement", JSON.stringify(response.data));
               $window.location.href = '#/agreement-description/';
            } else if (response.status === 429) {
               notificationservice.setNotification('profile.company_agreement_code.warn_timeout', 'warning');
            }
         });
      };

      $scope.deleteAgreementCode = function() {
         restservice.deleteAgreementCode().then(function(response) {
            if (response.status === 200) {
               $scope.agreementCodeValue = null;
               $scope.agreementCodeExists = false;
               $scope.showProfile();
            }
         });
      };

      $scope.showProfile = function() {
        $window.location.href = '#/profile';
      }
    }
  ]);

'use strict';

angular.module('extra2App')
   .directive('ckNotification', ['notificationservice', function (notificationservice) {
   return {
      templateUrl: 'views/directives/notification.html',
      restrict: 'E',
      scope: {
         notification: '='
      },
      link: function (scope, element, attrs) {

         scope.message = '';

         scope.getMessage = function() {
            scope.message = notificationservice.getNotification();
            return scope.message;
         };

         scope.active = notificationservice.getStatus();

         scope.$on('ui-notification', function(event, args) {
            scope.active = args.isActive;
            scope.notificationType = notificationservice.getType();
         });

         scope.close = function() {
            notificationservice.getCallback();
            scope.active = notificationservice.setStatus(false);
         }
      }
   };
}]);
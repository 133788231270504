(function () {

  'use strict';

  angular
    .module('extra2App')
    .controller('AppController', AppController);

	AppController.$inject = ['$scope', '$location', 'page', 'heromoduleservice'];
	
	function AppController($scope, $location, page, heromoduleservice) {

		$scope.page = page;
		$scope.currentPath = false;
		$scope.mastercardPath = false;

		$scope.$watch(function() { return heromoduleservice.getHero() },
			function() {
				$scope.hasHero = heromoduleservice.getHero();
			}
		);

    $scope.$on('$routeChangeStart', function (event) {
			$scope.currentPath = !!$location.path().match('dashboard');
			$scope.mastercardPath = !!$location.path().match('mastercard');
    });
   }

})();
'use strict';

angular.module('extra2App')
    .directive('ckPoints', ['$window', '$location', 'restservice', 'page', '$translatePartialLoader', '$translate', function ($window, $location, restservice, page, $translatePartialLoader, $translate) {
        return {
            templateUrl: 'views/directives/points.html',
            restrict: 'E',
            scope: { module: '=' },
            link: function (scope) {
                restservice.getToggle('POINTS_SUMMARY').then(function(toggleResponse) {
                    const bu = localStorage.getItem("businessUnit");
                    scope.showPointsSummary = toggleResponse.data ? bu !== 'PL' : true;
                });

                scope.points = scope.module[1].totalQuantity;

                $translatePartialLoader.addPart('points');
                $translate.refresh();

                scope.showPointsHistory = function() {
                    JSON.parse(localStorage.getItem("pointsModule"));
                    $location.path('points-history');
                }
            }
        };
    }]);
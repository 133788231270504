angular.module('extra2App')
   .controller('GlobalFailurePageController', ['$scope', 'restservice', '$translatePartialLoader', '$translate', '$location', 'page',
   function ($scope, restservice, $translatePartialLoader, $translate, $location, page) {

        $translatePartialLoader.addPart('globalfailure');
        $translate.refresh();

       page.setTitle("");

       $scope.goToDashboard = function() {
            $location.path("/dashboard");
        }

        $scope.logout = function () {
            restservice.logout();
        };

   }]);

angular.module('extra2App')
    .controller('TiersInfoController', ['$scope', '$routeParams', 'restservice', 'page', '$translatePartialLoader', '$translate', 'koppenservice', function ($scope, $routeParams, restservice, page, $translatePartialLoader, $translate, koppenservice) {
        $translatePartialLoader.addPart('tier');
        $translate.refresh();

        var tiersLevels = JSON.parse(localStorage.getItem("tiers_levels"));
        $scope.levels = tiersLevels;
        var levelsTitle = JSON.parse(localStorage.getItem("levels_title"));
        $scope.levels_title = levelsTitle;

    }]);

(function () {

	'use strict';

	angular
		.module('extra2App')
		.factory('page', Page);

	Page.$inject = ['$window'];

	function Page($window) {

		var title = '';
		var model = null;
		var loading = true;
		var selectedGraph = '';

		return {
			profileView: 'profile',
			title: function () {
				return title;
			},
			setTitle: function (pageTitle) {
				title = pageTitle;
			},
			model: function () {
				return model;
			},
			setModel: function (val) {
				model = val;
			},
			isLoading: function () {
				return loading;
			},
			setLoading: function (val) {
				loading = val;
			},
			getTransactionsView: function () {
				return selectedGraph;
			},
			setTransactionsView: function (val) {
				selectedGraph = val;
			},
			gotoView: function (url) {
				$window.location.href = '#/' + url;
			},
			back: function () {
				$window.history.back();
			}
		};
	}

})();

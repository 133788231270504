'use strict';

angular.module('extra2App').factory('koppenservice', [function() {

  var koppen = {
    current: null,
    target: null
  };

  var getKoppen = function(rollingmodel) {
    for (var i = 0; i < rollingmodel.length; i++) {
      if (rollingmodel[i].productGroupId == "koppen") {
        if (rollingmodel[i].target == 0) {
          koppen.current = "0";
          koppen.target = "5";
        } else if (rollingmodel[i].current < rollingmodel[i].target * 0.2) {
          koppen.current = "0";
          koppen.target = "5";
        } else if (rollingmodel[i].current >= rollingmodel[i].target * 0.2 && rollingmodel[i].current < rollingmodel[i].target * 0.4) {
          koppen.current = "1";
          koppen.target = "5";
        } else if (rollingmodel[i].current >= rollingmodel[i].target * 0.4 && rollingmodel[i].current < rollingmodel[i].target * 0.6) {
          koppen.current = "2";
          koppen.target = "5";
        } else if (rollingmodel[i].current >= rollingmodel[i].target * 0.6 && rollingmodel[i].current < rollingmodel[i].target * 0.8) {
          koppen.current = "3";
          koppen.target = "5";
        } else if (rollingmodel[i].current >= rollingmodel[i].target * 0.8 && rollingmodel[i].current < rollingmodel[i].target) {
          koppen.current = "4";
          koppen.target = "5";
        } else {
          koppen.current = "5";
          koppen.target = "5";
        }
      }
    }
    return koppen;
  };

  return {
    getKoppen: getKoppen
  };
}]);

'use strict';

angular.module('extra2App')
   .directive('ckTiersListItem', ['restservice', function (restservice) {
   return {
      templateUrl: 'views/directives/tiers-list-item.html',
      restrict: 'E',
      link: function (scope) {

            scope.iconsBasePath = restservice.getIconsBasePath();
            
         }
      };
}]);
'use strict';

angular.module('extra2App').service('APIInterceptor', function($rootScope, $location) {
    var service = this;

    service.responseError = function(response) {

        if ($location.path().match('/terms-and-conditions-public') ||
            $location.path().match('/global-failure-page') ||
            $location.path().match('/mastercard') ||
            $location.path().match('/coop-data-confirmation') ||
            $location.path().match('/denied-terms-and-conditions')) {
            return response;
        }

        if (response.status === 401 && !$location.path().match('/logout')) {
            $location.path("/login");
        } else if (response.status === 500) {
            $location.path("/global-failure-page");
        }
        return response;
    };
});

'use strict';

angular.module('extra2App').factory('agreementservice', ['$location', '$window', 'restservice', function($location, $window, restservice){
  var response = null;
  var showHideTcButton = false;

    var getAgreement = function() {
      return response;
    };

    var setAgreement = function(val){
      response = val;
    };

    var getShowHideTcButton = function() {
      return showHideTcButton;
    };

    var setShowHideTcButton = function(val){
      showHideTcButton = val;
    };

	return {
        getAgreement: getAgreement,
        setAgreement: setAgreement,
        getShowHideTcButton: getShowHideTcButton,
        setShowHideTcButton: setShowHideTcButton
	};
}]);

'use strict';

angular.module('extra2App').factory('tierstransactionservice', ['$q','restservice', 'localizationservice',
    function($q, restservice, localizationservice){

        var loadPurchases = function(selectedMonth) {

            if (selectedMonth && !selectedMonth.loaded) {
                restservice.getTiersTransactions().then(function(result) {
                    selectedMonth.loaded = true;
                    selectedMonth.purchases = [];
                    selectedMonth.totalFuel = 0;
                    if (result.data) {
                        selectedMonth.noTransactions = result.data.totalSpent == 0 ? true : false;
                        selectedMonth.totalCostText = result.data.totalSpent.toFixed(2).toString().replace(".", ",");
                        selectedMonth.localCurrencyCode = result.data.currencyCode;
                        if (result.data.purchases) {
                            result.data.purchases.forEach(function(purchaseResult) {
                                var purchase = purchaseResult;
                                var totalPurchaseAmount = Number(purchase.totalPurchaseAmount);
                                // EXTTWO-1159: As an Extra member I want the points overview to only display relevant information
                                // Value 0.01 in Poland is caused by value needed for printing receipt
                                    purchase.purchaseDate = new Date(purchase.purchaseDate + "+00:00"); // EXTTWO-1742...otherwise time is in wrong timezone.
                                    purchase.productGroupTitle = localizationservice.getLocalTitleOfTranslationId(purchase.productGroupIcon);
                                    purchase.productAmountText = totalPurchaseAmount.toFixed(2).toString().replace(".", ",");

                                    if (totalPurchaseAmount < 0) {
                                        purchase.state = 'refund';
                                        purchase.productAmountText = purchase.productAmountText.substring(1);
                                    } else {
                                        purchase.productAmountText = totalPurchaseAmount.toFixed(2).toString().replace(".", ",");
                                    }
                                    if (totalPurchaseAmount != 0) {
                                        selectedMonth.purchases.push(purchase);
                                    }
                            })
                        }
                    }
                })
            }
        };

        return {
            loadPurchases: loadPurchases
        };
    }]);
'use strict';

angular.module('extra2App').factory('localizationservice', ['$translatePartialLoader', '$translate', function($translatePartialLoader, $translate) {
    
  $translatePartialLoader.addPart('extra2ui');
  $translatePartialLoader.addPart('productgrouptitles');
  $translate.refresh();
  
  var langmap = {};
  langmap['NO'] = 'nb';
  langmap['SE'] = 'sv';
  langmap['DK'] = 'da';
  langmap['EE'] = 'et';
  langmap['LV'] = 'lv';
  langmap['LT'] = 'lt';
  langmap['PL'] = 'pl';
  langmap['RU'] = 'ru';

  var setLanguage = function (user) {
      var bu = user.businessUnit;
      if (bu in langmap) {
        $translate.use(langmap[bu]);
        $translate.refresh();
      } else {
        $translate.use('en');
        $translate.refresh();
      }
  };

  var getLocalMonthShortUppercase = function(month) {
      // Prepend month with "0" if necessary
      var monthText = $translate.instant("extra2ui.month_" + ("0" + month).slice(-2)) + "_short";
      return monthText.toUpperCase().substring(0,3);
  };
  
  var getLocalMonthLongCapitalized = function(month) {
      // Prepend month with "0" if necessary
      var monthText = $translate.instant("extra2ui.month_" + ("0" + month).slice(-2));
      return monthText.charAt(0).toUpperCase() + monthText.slice(1);
  };

  var getLocalTitleOfTranslationId = function (translationId) {
      var key = 'productgrouptitle.' + translationId;
      var productGroupTitle = $translate.instant(key);
      if (productGroupTitle === key) {
        key = 'productgrouptitle.misc';
        productGroupTitle = $translate.instant(key);
      }
      return productGroupTitle;
  };

  return {
      setLanguage: setLanguage,
      getLocalMonthShortUppercase: getLocalMonthShortUppercase,
      getLocalMonthLongCapitalized: getLocalMonthLongCapitalized,
      getLocalTitleOfTranslationId: getLocalTitleOfTranslationId

  };
}]);
angular
  .module('extra2App')
  .controller('LinkCardPreStepController', LinkCardPreStepController);

LinkCardPreStepController.$inject = ['$window', '$location', '$scope', '$translatePartialLoader', '$translate', 'cardservice', 'page', 'restservice'];

function LinkCardPreStepController($window, $location, $scope, $translatePartialLoader, $translate, cardservice, page, restservice) {

  $scope.selected = {card: ""};

  restservice.getLoginStatus().then(function (result) {
    user = result.data;
    $scope.businessUnit = user.businessUnit;
  });

  $scope.showUnlockInfo = function () {
    return ['NO', 'SE', 'DK', 'LT', 'LV', 'EE', 'PL'].includes($scope.businessUnit);
  };

  var origin = $location.search().origin;
  $scope.showProfileButton = !(origin === 'dashboard');
  $scope.hidePremiumCardCheckbox = origin === 'profile';
  $scope.showSkipButton = origin === 'dashboard';

  cardservice.getCardChoices().then(function (choices) {
    $scope.cardChoices = choices.data;
  });

  $translatePartialLoader.addPart('linkcard');
  $translate.refresh();

  page.setTitle($translate.instant('link_card.prestep.title'));

  $scope.linkCard = function () {
    var card = JSON.parse($scope.selected.card);
    if (card) {
      cardservice.initLinkCard(origin, card.cardName);
    }
  };

  $scope.showProfile = function () {
    $window.location.href = '#/profile';
  };

  $scope.skipAddingCard = function () {
    restservice.skipAddingCard().then(function(response) {
      if (response.status === 200) {
        $window.location.href = '#/dashboard';
      }
    });
  };
}

'use strict';

angular.module('extra2App')
   .directive('ckBanners', ['$window', '$translatePartialLoader', '$translate', 'page', 'restservice',
      function ($window, $translatePartialLoader, $translate, page, restservice) {
         return {
            templateUrl: 'views/directives/banners.html',
            restrict: 'E',
            scope: { module: '=' },
            link: function (scope) {

             	$translatePartialLoader.addPart('banners');
             	$translate.refresh();
               scope.bannerData = scope.module[1].values;

               scope.getType = function (item) {
                  item.css('height', '100px');
               };

               scope.getBanner = function (item) {
                  dataLayer.push({'promo': item.productGroupId, 'event': 'promoClick'});

                  if (item.linkPath != null) {
                     $window.open(item.linkPath);
                  }
               }
            }
         };
      }]);

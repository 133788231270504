angular.module('extra2App').service('languagestorageservice', function($cookies) {

    // var languageFromBrowser = null;;
    //
    // this.setLanguageFromBrowser = function(language) {
    //     languageFromBrowser = language;
    // };

    return {
        get: function(name) {
            return $cookies.get(name);
        },
        put: function(name, value) {
            // if (value === languageFromBrowser) {
            //     $cookies.remove(name, {path: '/', domain: document.domain.split('.').slice(-2).join('.')});
            //     return;
            // }
            var exp = new Date();
            exp.setFullYear(exp.getFullYear() + 1);
            $cookies.put(name, value, {
                path: '/',
                domain: document.domain.split('.').slice(-2).join('.'),
                expires: exp
            });
        }
    }
});
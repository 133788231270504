angular.module('extra2App')
    .controller('RollingModelsDetailController', ['$scope', '$routeParams', 'restservice', 'page', '$translatePartialLoader', '$translate', 'koppenservice', function ($scope, $routeParams, restservice, page, $translatePartialLoader, $translate, koppenservice) {
        $translatePartialLoader.addPart('rollingmodels');
        $translate.refresh();

        var rollingModel = JSON.parse(localStorage.getItem("rolling_model"));

        if (rollingModel) {
            $scope.rollingModel = rollingModel;
        }

        $translate('rolling_models.' + rollingModel.productGroupId + '.title').then(function (translation) {
            page.setTitle(translation);
        });

        $scope.koppen = koppenservice.getKoppen([rollingModel]);

    }]);

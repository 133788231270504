'use strict';

angular.module('extra2App')
    .directive('ckAwards', ['$location','restservice', 'page', '$translatePartialLoader', '$translate', 'localizationservice',
        function ($location, restservice, page, $translatePartialLoader, $translate, localizationservice) {
        return {
            templateUrl: 'views/directives/awards.html',
            restrict: 'E',
            scope: { module: '=' },
            link: function (scope) {

                $translatePartialLoader.addPart('extra2ui');
                $translatePartialLoader.addPart('awards');
                $translate.refresh();

                scope.awardsData = scope.module[1].values;
                scope.iconsBasePath = restservice.getIconsBasePath();

                var date;
                scope.showAwardDetails = function(item) {
                    item.iconsBasePath = scope.iconsBasePath;
                    for (date in item.expirationDates) {
                        var dateObj = new Date(item.expirationDates[date]);
                        var month = dateObj.getMonth() + 1;
                        item.expirationDates[date] =
                            localizationservice.getLocalMonthLongCapitalized(month);

                        item.expirationDates[date] = item.expirationDates[date]
                            + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear();
                    }

                localStorage.setItem("award", JSON.stringify(item));

                $location.path('awards-detail');
            }
        }
    };
}]);

(function () {

  'use strict';

  angular
    .module('extra2App')
    .config(RouteConfig);

  function RouteConfig($routeProvider) {

    $routeProvider
      .when('/dashboard', {template: `<dashboard></dashboard>`, controllerAs: 'vm'})
      .when('/dashboard/aborted', {template: `<dashboard></dashboard>`, controllerAs: 'vm'})
      .when('/mastercard', {template: `<mastercard></mastercard>`})
      .when('/mastercard-redirect', {template: `<mastercard-redirect></mastercard-redirect>`})
      .when('/coop-data-confirmation', {template: `<coop-data-confirmation></coop-data-confirmation>`})
      .when('/coop-agreement-confirmation', {template: `<coop-agreement-confirmation></coop-agreement-confirmation>`})
      .when('/login', {templateUrl: 'views/login.html', controller: 'LoginController'})
      .when('/purchases', {templateUrl: 'app/views/purchases.html', controller: 'PurchasesController'}) // TODO remove?
      .when('/my-transactions', {templateUrl: 'views/my-transactions.html', controller: 'MyTransactionsController'})
      .when('/tiers-transactions', {templateUrl: 'views/tiers-transactions.html', controller: 'TiersTransactionsController'})
      .when('/points-history', {templateUrl: 'views/points-history.html', controller: 'PointsHistoryController'})
      .when('/card-subpage', {templateUrl: 'views/card-subpage.html', controller: 'CardSubpageController'})
      .when('/rolling-models-detail', {templateUrl: 'views/rolling-models-detail.html', controller: 'RollingModelsDetailController'})
      .when('/tiers-info', {templateUrl: 'views/tiers-info.html', controller: 'TiersInfoController'})
      .when('/awards-detail', {templateUrl: 'views/awards-detail.html', controller: 'AwardsDetailController'})
      .when('/profile', {templateUrl: 'views/profile.html', controller: 'ProfileController'})
      .when('/profile/aborted', {templateUrl: 'views/profile.html', controller: 'ProfileController'})
      .when('/link-card-failure', {templateUrl: 'views/link-card-failure.html', controller: 'LinkCardFailureController'})
      .when('/global-failure-page', {templateUrl: 'views/global-failure-page.html', controller: 'GlobalFailurePageController'})
      .when('/extra-club-page', {templateUrl: 'views/extra-member-page.html', controller: 'ExtraMemberPageController'})
      .when('/link-card-pre-step', {templateUrl: 'views/link-card-pre-step.html', controller: 'LinkCardPreStepController'})
      .when('/communication-preferences-prestep', {templateUrl: 'views/communication-preferences-prestep.html', controller: 'CommPrefPreStepController'})
      .when('/link-account-by-card', {templateUrl: 'views/link-account-by-card.html', controller: 'LinkAccountByCardController'})
      .when('/logout', {templateUrl: 'app/views/logout.html', controller: 'LogoutController'})
      .when('/terms-and-conditions', {templateUrl: 'views/terms-and-conditions.html', controller: 'TermsConditionsController'})
      .when('/denied-terms-and-conditions', {templateUrl: 'views/denied-terms-and-conditions.html', controller: 'DeniedTermsConditionsController'})
      .when('/agreement-code', {templateUrl: 'views/agreement-code.html', controller: 'AgreementCodeController'})
      .when('/add-agreement-code', {templateUrl: 'views/add-agreement-code.html', controller: 'AddAgreementCodeController'})
      .when('/communication-preferences', {templateUrl: 'views/communication-preferences.html', controller: 'CommunicationPreferencesController'})
      .when('/account-action', {templateUrl: 'views/account-action.html', controller: 'AccountActionController'})
      .when('/admin', {templateUrl: 'views/admin.html', controller: 'AdminController'})
      .when('/agreement-description', {templateUrl: 'views/agreement-description.html', controller: 'AgreementDescriptionController'})
      .when('/activate-agreement', {templateUrl: 'views/activate-agreement.html', controller: 'ActivateAgreementController'})
      .when('/privacy-policy', {templateUrl: 'views/privacy-policy.html', controller: 'PrivacyPolicyController'})
      .when('/pepsi-promotion', {templateUrl: 'views/pepsi-promotion.html', controller: 'PepsiPromotionController'})
      .when('/coca-cola-promotion', {templateUrl: 'views/cola-promotion.html', controller: 'ColaPromotionController'})

      .otherwise({
        redirectTo: function () {
          return '/dashboard' + location.search;
        }
      });
  }
})();

(function () {

'use strict';

angular
    .module('extra2App')
    .factory('restservice', ['$http', '$q', '$window', '$location', 'localizationservice', '$cookies',
      function ($http, $q, $window, $location, localizationservice, $cookies) {

        var appConfig = {};
        var settingsUrl = 'api/v1/unres/appconfig';
        var iconsBasePath = 'images/icons/';
        var uuid = appConfig && appConfig.uuid ? appConfig.uuid : null;
        var user = null;
        var settings; // TODO refactor AppConfig to not fetch endpoints urls from backend in LOY-535

        var getFetchedSettings = function () {
            if (user && uuid) {
                localStorage.setItem("businessUnit", user.businessUnit);
                if (user.warnings.includes('EMPTY_BIRTHDAY') && !user.birthDate) {
                    triggerAccountActionInternal();
                }
            }
            var deferred = $q.defer();
            getSettings().then(function (fetchedSettings) {
                settings = fetchedSettings;
                if (!uuid) {
                    getLoginStatusInternal().then(function (result) {
                        user = result.data;
                        if (user.businessUnit) {
                            localizationservice.setLanguage(user);
                        }
                        uuid = user.id;
                        if (user.warnings.includes('EMPTY_BIRTHDAY') && !user.birthDate) {
                            triggerAccountActionInternal();
                        }
                        deferred.resolve();
                    })
                } else
                    deferred.resolve();
            });
            return deferred.promise;
        };

        function getSettings() {
            return $http.get(settingsUrl, {cache: true}).then(function (result) {
                return result.data;
            });
        }

        function getIconsBasePath() {
            return iconsBasePath;
        }

        function getLoginStatusInternal() {
            return $http({
                url: settings.apiUnresURI + settings.loginStatusUrl,
                method: 'GET',
                cache: false
            });
        }

        function getPurchases(year, month, filter) {
            return getFetchedSettings()
                .then(function () {

                    if (uuid === '.json')
                        return getData(settings.apiResURI + settings.purchasesUrl + uuid);
                    else if (month.toString().length === 1) {
                        month = "0" + month;
                    }
                    return getData(settings.apiResURI + "purchasespermonth" + '/' + year + month + '/?filterPoints=' + filter);
                })
        }

        function getDashboardModules() {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + settings.dashboardUrl);
                });
        }

        function getWebshop() {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + "webshopdata");
                });
        }

        function getPaymentCards() {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + settings.paymentCardsUrl);
                });
        }

        function getLoginStatus() {
            return getSettings()
                .then(function () {
                    return getLoginStatusInternal();
                });
        }

        function triggerAccountActionInternal() {
            // Make sure user is refreshed
            uuid = null;
            $location.path('/account-action');
        }

        function triggerInitiateLinkCard(originPage, prefix) {
            if (!prefix) {
                $location.path('/link-card-pre-step').search('origin', originPage);
            } else {
                var linkCardRequest = settings.initLinkCardUrl + originPage;
                if (uuid !== '.json') {
                    linkCardRequest += '/' + prefix;
                }

                getData(settings.apiResURI + linkCardRequest).then(function (response) {
                    $window.location.href = response.data.redirectUri;
                });
            }
        }

        function linkAccountByCard(cardNumberValue) {
            return getSettings()
                .then(function () {
                    return postData(settings.apiResURI + settings.linkAccountByCardUrl + cardNumberValue, false);
                });
        }

        function getCardChoices() {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + settings.cardChoices, false);
                });
        }

        function getAdminRole() {

            var deferred = $q.defer();

            getFetchedSettings().then(function () {

                var adminRole;

                if (user) {
                    adminRole = user.adminRole;

                    deferred.resolve(adminRole);
                }
            });

            return deferred.promise;
        }

        function updateBirthdate(birthDate) {
            return getFetchedSettings()
                .then(function () {
                    return postData(settings.apiResURI + settings.updateBirthdateUrl + birthDate, false);
                });
        }

        function updateAgreementCode(agreementCode, partnerInfoId) {
            return getFetchedSettings()
                .then(function () {
                    var data = {
                        "agreementCode": agreementCode,
                        "partnerInfoId": partnerInfoId
                    };
                    return $http({
                        url: settings.apiResURI + settings.selfSetAgreementCodeUrl,
                        method: 'POST',
                        data: JSON.stringify(data),
                        cache: false
                    });
                });
        }

        function uploadAdminContent(content) {
            return getFetchedSettings()
                .then(function () {
                    return $http({
                        url: settings.apiAdminURI + settings.uploadContentUri,
                        method: 'POST',
                        data: angular.toJson(content),
                        cache: false
                    });
                });
        }

        function updateAdminContent(banner) {
            return getFetchedSettings()
                .then(function () {
                    return $http({
                        url: settings.apiAdminURI + settings.updateContent,
                        method: 'PUT',
                        data: angular.toJson(banner),
                        cache: false
                    });
                });
        }

        function deleteModuleData(uri) {
            return getFetchedSettings().then(function () {
                return data('DELETE', settings.apiAdminURI + uri, false);
            });
        }

        function acceptAgreementCode(agreement, partnerInfoId) {
            return getFetchedSettings()
                .then(function () {
                    data = {
                        "descriptionShown": true,
                        "agreementCode": agreement.agreementCode,
                        "acceptedTandC": true,
                        "checkword": agreement.checkword,
                        "overwriteActiveAgreement": true,
                        "partnerInfoId": partnerInfoId
                    };
                    return $http({
                        url: settings.apiResURI + settings.selfSetAgreementCodeUrl,
                        method: 'POST',
                        data: JSON.stringify(data),
                        cache: false
                    });
                });
        }

        function deleteAgreementCode() {
            return getFetchedSettings()
                .then(function () {
                    return deleteData(settings.apiResURI + settings.selfSetAgreementCodeUrl, false);
                });
        }

        function getMemberProfile() {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + settings.memberProfileUrl, false);
                });
        }

        function getMemberAgreementCode(agreementCode) {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + settings.memberAgreementCodeUrl + '/' + agreementCode, false);
                });
        }

        function getCommunicationPreferences() {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + settings.memberCommPrefsUrl, false);
                });
        }

        function setCommunicationPreferences(emailEnabled, smsEnabled, pushEnabled, surveyEnabled) {
            return getFetchedSettings()
                .then(function () {
                    var data = {
                        "emailEnabled": emailEnabled,
                        "smsEnabled": smsEnabled,
                        "pushEnabled": pushEnabled,
                        "surveyEnabled": surveyEnabled
                    };
                    return $http({
                        url: settings.apiResURI + settings.updateCommunicationPreferencesUrl,
                        method: 'POST',
                        data: JSON.stringify(data),
                        cache: false
                    });
                });
        }

        function acceptMarketingCommunication() {
            return getFetchedSettings()
                .then(function () {
                    return $http({
                        url: settings.apiResURI + settings.marketingConsentUrl,
                        method: 'POST',
                        cache: false
                    });
                });
        }

        function declineMarketingCommunication() {
            return getFetchedSettings()
            .then(function () {
                return $http({
                    url: settings.apiResURI + settings.marketingConsentUrl,
                    method: 'DELETE',
                    cache: false
                });
            });
        }

        function unlinkPaymentCard(encryptedCardNumber) {
            return getFetchedSettings()
                .then(function () {
                    return postData(settings.apiResURI + settings.unlinkPaymentCardUrl + encryptedCardNumber, false);
                });
        }

        function memberNotified(notify) {
            if (notify === undefined) {
                notify = false;
            }
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + settings.memberNotified + notify, false);
                });
        }

        function logout(partnerClientId) {
            var circleKidSingleLogoutUrl;
            if (partnerClientId) {
                circleKidSingleLogoutUrl = settings.circleKidSingleLogoutUrlWithoutClientId + partnerClientId;
                if ($cookies.get('PARTNER_CLIENT_ID')) {
                    $cookies.remove('PARTNER_CLIENT_ID');
                }
            } else {
                circleKidSingleLogoutUrl = settings.circleKidSingleLogoutUrl;
            }

            return getSettings()
                .then(function (settings) {
                    postData(settings.apiUnresURI + settings.logoutUrl, false)
                        .then(function (response) {
                            if (response.status === 200 && circleKidSingleLogoutUrl !== "") {
                                localStorage.clear();
                                $window.location.href = circleKidSingleLogoutUrl;
                            }
                        });
                });
        }

        function getData(fullUrl, cache) {
            return callRestService(fullUrl, 'GET', cache);
        }

        function postData(fullUrl, cache) {
            return callRestService(fullUrl, 'POST', cache);
        }

        function deleteData(fullUrl, cache) {
            return callRestService(fullUrl, 'DELETE', cache);
        }

        function callRestService(fullUrl, method, cache) {
            return $http({
                url: fullUrl,
                method: method,
                cache: cache
            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                return response;
            }, function errorCallback() {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
                $location.path("/global-failure-page");
            });
        }

        function getModuleData(uri) {
            return getFetchedSettings().then(function () {
                return data('GET', uri, false);
            });
        }

        function getAdminModules() {
            return getFetchedSettings().then(function () {
                return data('GET', settings.apiResURI + settings.adminUrl);
            });
        }

        function data(method, uri, cache) {
            return callRestService(uri, method, cache);
        }

        function getVersion() {
            return getFetchedSettings().then(function () {
                return data('GET', settings.apiUnresURI + 'version', false);
            });
        }

        function authorizePartner(agreementDraftId) {
          return getData('/api/v1/res/authorizePartner/' + agreementDraftId, false);
        }

        function getToggle(toggle) {
          return $http.get('/api/v1/technical/toggle/' + toggle, {
            headers: {'Authorization': 'Basic ' + btoa('feature_admin:fadm4321')}
          }).then(function successCallback(response) {
            return response;
          }, function errorCallback() {
            $location.path("/global-failure-page");
          });
        }

        function mastercardRedirect(agreementId) {
          return getData('/api/v1/res/mastercard/agreement/' + agreementId, false);
        }

        function coopAgreementConfirmation(agreementId) {
          return getData('/api/v1/res/coop/agreement/' + agreementId, false);
        }

        function getTiersTransactions() {
          return getData('/api/v1/res/tierTransactions', false);
        }

        function getPartnerInfo(partnerInfoId) {
            return getFetchedSettings()
                .then(function () {
                    return getData('/api/v1/external/partner/info/' + partnerInfoId, false)
                });
        }

        function addPartnerAgreement(partnerInfoId, uuid) {
            return getFetchedSettings()
                .then(function () {
                    return postData('/api/v1/external/partner/partneragreement/' + partnerInfoId + '/' + uuid, false)
                });
        }

        function deletePartnerInfo(partnerInfoId) {
            return getFetchedSettings()
                .then(function () {
                    return postData('/api/v1/external/partner/deletepartnerinfo/' + partnerInfoId)
                });
        }

        function remainMember() {
            return getFetchedSettings()
                .then(function () {
                    return getData(settings.apiResURI + 'remainmember', false)
                })
        }

        function enablePepsiMarketingDetails() {
            return postData("/api/v1/res/addpepsimarketingdetails", false);
        }

        function enableColaMarketingDetails() {
          return postData("/api/v1/res/addcolamarketingdetails", false);
        }

        function skipAddingCard() {
          return postData("/api/v1/res/loyaltycard/skip", false);
        }

        function redirectWhenDecline() {
            var agreementActivation;
            var agreeCookie = $cookies.get('PART_AGREE');
            if (agreeCookie) {
                agreementActivation = JSON.parse(agreeCookie);
            }
            if (agreementActivation) {
                var partnerInfoId = agreementActivation['partnerInfoId'];
                var circleKidAuthorizeBaseUrl = "";
                var urlParams = "";
                getPartnerInfo(partnerInfoId).then(function (response) {
                    if (response.status === 200) {
                        if (isEmpty(response.data)) {
                            $location.path('/global-failure-page');
                        } else {
                            var partnerInfo = response.data;
                            urlParams = "?ui_customization=extra2&client_id=" + partnerInfo.partnerClientId + "&scope=" + partnerInfo.partnerScope;
                            if (partnerInfo.redirectURI) {
                                urlParams += "&redirect_uri=" + partnerInfo.redirectURI;
                            }
                            urlParams += "&response_type=" + partnerInfo.responseType + "&state=" + partnerInfo.partnerState + "&code_challenge=" + partnerInfo.codeChallenge;
                            getSettings().then(function (settings) {
                                circleKidAuthorizeBaseUrl = settings.circleKidAuthorizeBaseUrl;
                            }).then(function () {
                                deletePartnerInfo(partnerInfoId);
                                $cookies.remove('PART_AGREE');
                                $window.location.href = circleKidAuthorizeBaseUrl + urlParams;
                            })
                        }
                    }
                })

            } else {
                $window.location.href = "#/profile";
            }
        }

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return false;
                }
            }
            return true;
        }

        return {
            getSettings: getSettings,
            getIconsBasePath: getIconsBasePath,
            getPurchases: getPurchases,
            getLoginStatus: getLoginStatus,
            getDashboardModules: getDashboardModules,
            triggerInitiateLinkCard: triggerInitiateLinkCard,
            linkAccountByCard: linkAccountByCard,
            getCardChoices: getCardChoices,
            getAdminRole: getAdminRole,
            logout: logout,
            getPaymentCards: getPaymentCards,
            updateBirthdate: updateBirthdate,
            updateAgreementCode: updateAgreementCode,
            deleteAgreementCode: deleteAgreementCode,
            getMemberProfile: getMemberProfile,
            getMemberAgreementCode: getMemberAgreementCode,
            getCommunicationPreferences: getCommunicationPreferences,
            setCommunicationPreferences: setCommunicationPreferences,
            acceptMarketingCommunication: acceptMarketingCommunication,
            declineMarketingCommunication: declineMarketingCommunication,
            unlinkPaymentCard: unlinkPaymentCard,
            getWebshop: getWebshop,
            acceptAgreementCode: acceptAgreementCode,
            getModuleData: getModuleData,
            deleteModuleData: deleteModuleData,
            uploadAdminContent: uploadAdminContent,
            updateAdminContent: updateAdminContent,
            getAdminModules: getAdminModules,
            getVersion: getVersion,
            getToggle: getToggle,
            authorizePartner: authorizePartner,
            mastercardRedirect: mastercardRedirect,
            coopAgreementConfirmation: coopAgreementConfirmation,
            getPartnerInfo: getPartnerInfo,
            addPartnerAgreement: addPartnerAgreement,
            memberNotified: memberNotified,
            remainMember: remainMember,
            redirectWhenDecline: redirectWhenDecline,
            getTiersTransactions: getTiersTransactions,
            enablePepsiMarketingDetails: enablePepsiMarketingDetails,
            enableColaMarketingDetails: enableColaMarketingDetails,
            skipAddingCard: skipAddingCard
        }
    }]);

})();

angular.module('extra2App')
   .controller('ColaPromotionController', ['$scope', '$sce', 'page', 'restservice', '$translatePartialLoader', '$translate', '$window',
      function ($scope, $sce, page, restservice, $translatePartialLoader, $translate, $window) {

         $translatePartialLoader.addPart('colapromotion');
         $translate.refresh();

         $scope.joinButtonEnabled = false;

         page.setTitle($translate.instant('cola_promotion.title'));

         $scope.joinColaPromo = function() {
            restservice.enableColaMarketingDetails().then(function (response) {
               if(response.status === 200) {
                  $window.location.href = '#/dashboard';
               } else {
                  $location.path('/global-failure-page');
               }
            });
         }

         $scope.skipColaPromo = function() {
            $window.location.href = '#/dashboard';
         }
   }]);

'use strict';

angular.module('extra2App').factory('notificationservice', ['$rootScope', '$translate', '$timeout', function($rootScope, $translate, $timeout){
   
   var message = '';
   var notificationType = '';
   var isActive = false;
   var timer = 5000;
   var callback = null;

   var setNotification = function(msg, type, c) {
   	message = msg;
      setType(type);
      if (c) {
         callback = c;
      }

   	$translate(message).then(function (result) {
			message = result;
			setStatus(true);
			$rootScope.$broadcast('ui-notification', { isActive:true });

			if (getStatus()) {
   			$timeout(function() {
   				setStatus(false);
   				// $rootScope.$broadcast('ui-notification', { isActive:false });
   			}, timer);
   		}
		});
   };

   var getNotification = function() {
   	return message;
   };

   var setType = function(type) {
      notificationType = type;
   };

   var getType = function() {
      return notificationType;
   };

   var setStatus = function(status) {
   	isActive = status;
   };

   var getStatus = function() {
   	return isActive;
   };

   var getCallback = function() {
      if (callback) {
         return callback();
      }
   };

   return {
   	setStatus: setStatus,
   	getStatus: getStatus,
      getType: getType,
      setNotification: setNotification,
      getNotification: getNotification,
      getCallback: getCallback
   };
}]);

'use strict';

angular.module('extra2App').factory('heromoduleservice', function(){

    var hero = {
        ModuleType: ''
    };

    return {
        getHero: function () {
            return hero.ModuleType;
        },
        setHero: function (moduleType) {
            hero.ModuleType = moduleType;
        }
    };
});
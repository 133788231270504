angular.module('extra2App')
  .controller('CardSubpageController', ['$scope', '$window', '$translatePartialLoader', '$translate', 'restservice', 'page', 'notificationservice',
    function ($scope, $window, $translatePartialLoader, $translate, restservice, page, notificationservice) {

      $scope.paymentCard = JSON.parse(localStorage.getItem("paymentCard"));
      $scope.showMasterCardLoginButton = $scope.paymentCard.cmc;
      $scope.showRemoveCardButton = (localStorage.getItem("moreThanOneCard") == "true");

      $scope.cardBackground = function () {
        var backgroundImage = $scope.paymentCard.cmc ? 'card-cmc-bg.svg' : 'card-bg.svg';
        return {
          'background': 'url(/images/' + backgroundImage + ')'
        };
      };

      page.setTitle($scope.paymentCard.cardType);
      $translatePartialLoader.addPart('profile');
      $translatePartialLoader.addPart('linkcard');
      $translatePartialLoader.addPart('globalfailure');
      $translate.refresh();

      $scope.removeCard = function () {
        restservice.unlinkPaymentCard($scope.paymentCard.encryptedCardNumber).then(function (response) {
          if (response.status === 200) {
            localStorage.removeItem("paymentCard");
            $window.location.href = '#/profile';
          } else if (response.status === 406) {
            notificationservice.setNotification('profile.remove_card.cmc.warning', 'warning');
          } else {
            notificationservice.setNotification('global_failure.title', 'warning', $scope.notifyMember);
          }
        });
      };

      $scope.showProfile = function () {
        $window.location.href = '#/profile';
      }

      $scope.openMasterCardLoginUrl = function () {
        $translate('extra2ui.mastercard_login.link_path').then(function (translation) {
          $window.open(translation);
        });
      }

    }]);

angular.module('extra2App')
    .controller('AwardsDetailController', ['$scope', 'restservice', 'page', '$translatePartialLoader', '$translate', function ($scope, restservice, page, $translatePartialLoader, $translate) {
        $translatePartialLoader.addPart('awards');
        $translate.refresh();

        var award = JSON.parse(localStorage.getItem("award"));

        if (award) {
            $scope.award = award;
        }

        $translate('awards.' + award.productGroupId + '.title').then(function (translation) {
            page.setTitle(translation);
        });

    }]);


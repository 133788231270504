'use strict';

angular.module('extra2App')
    .directive('fileread', ['$location','restservice', 'page', '$translatePartialLoader', '$translate', 'localizationservice',
        function ($location, restservice, page, $translatePartialLoader, $translate, localizationservice) {
    	return {
    	      scope: {
    	        fileread: "="
    	      },
    	      link: function(scope, element, attributes) {

    	    	  element.bind("change", function(changeEvent) {
    	              var reader = new FileReader();
    	              reader.onload = function(loadEvent) {
    	                scope.$apply(function() {
    	                  scope.fileread = loadEvent.target.result;
    	                });
    	              }
    	              reader.readAsDataURL(changeEvent.target.files[0]);
    	       });
    	    	  
        }
    };
}]);

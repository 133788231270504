import '../styles/main.scss';

import angular from 'angular';
import 'angular-route';
import 'angular-cookies';

'use strict';

export const extra2App = angular
  .module(
    'extra2App',
    ['ngRoute', 'ngSanitize', 'pascalprecht.translate', 'angular-datalayer', 'ngCookies']
  );

angular.module('extra2App')
	.controller('ActivateAgreementController', ['$scope', '$location', '$window', 'restservice', 'agreementservice', '$cookies', '$translatePartialLoader', '$translate', 'notificationservice',
	  function ($scope, $location, $window, restservice, agreementservice, $cookies, $translatePartialLoader, $translate, notificationservice) {

		$translatePartialLoader.addPart('profile');
		$translate.refresh();

		// set expiration time on cookie
		var today = new Date();
		var expiresValue = new Date(today);
		expiresValue.setMinutes(today.getMinutes() + 10);

		var circleKidAuthorizeBaseUrl = "";
		var agreementActivation;
		var agreeCookie = $cookies.get('PART_AGREE');

		if (agreeCookie) {
			agreementActivation = JSON.parse(agreeCookie);
		}

		var action = agreementActivation['action'];
		if (action === "consent_to_agreement") {
			var agreementCode = agreementActivation['agreement_code'];
			agreementActivation['action'] = "authorize_new_partner_user";
			$cookies.put('PART_AGREE', JSON.stringify(agreementActivation), {'expires' : expiresValue});
			setAgreement(agreementCode, agreementActivation['partnerInfoId']);
		} else {
			var nextAction = agreementActivation['action'];
			if (nextAction === "authorize_new_partner_user") {
				var partnerInfoId = agreementActivation['partnerInfoId'];
				restservice.getPartnerInfo(partnerInfoId).then(function (response) {
					if (response.status === 200) {
						if (isEmpty(response.data)) {
							$location.path('/global-failure-page');
						} else {
							var partnerInfo = response.data;
							var urlParams = "?ui_customization=extra2&client_id=" + partnerInfo.partnerClientId + "&scope=" + partnerInfo.partnerScope;
							if (partnerInfo.redirectURI) {
								urlParams += "&redirect_uri=" + partnerInfo.redirectURI;
							}
							urlParams += "&response_type=" + partnerInfo.responseType + "&state=" + partnerInfo.partnerState + "&code_challenge=" + partnerInfo.codeChallenge;
							restservice.getSettings().then(function (settings) {
								circleKidAuthorizeBaseUrl = settings.circleKidAuthorizeBaseUrl;
							}).then(function () {
								if (circleKidAuthorizeBaseUrl !== "") {
									restservice.getLoginStatus().then(function (result) {
											restservice.addPartnerAgreement(partnerInfoId, result.data.id).then(function (response) {
												if(response.status === 200){
													$cookies.remove('PART_AGREE');
													$window.location.href = circleKidAuthorizeBaseUrl + urlParams;
												}
												else {
													$location.path('/global-failure-page');
												}
											})
									});
								}
							});
						}
					}
				});
			}
		}

		function setAgreement(agreementCodeValue, partnerInfoId) {
			restservice.updateAgreementCode(agreementCodeValue, partnerInfoId).then(function (response) {
				if (response.status === 200) {
					$scope.showConfirmationLabel = true;
					$scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.exists');
					$scope.agreementCodeValue = agreementCodeValue;
					$scope.agreementCodeExists = true;
				} else if (response.status === 404 || response.status === 400) {
					$scope.agreementCodeMessage = $translate.instant('profile.company_agreement_code.bad_code');
				} else if (response.status === 403) {
					agreementservice.setAgreement(response.data);
					localStorage.setItem("selectedAgreement", JSON.stringify(response.data));

					restservice.getLoginStatus().then(function (result) {
						if(result.data.birthDate) {
							$window.location.href = '#/agreement-description/';
						}
					});
				} else if (response.status === 429) {
					 notificationservice.setNotification('profile.company_agreement_code.warn_timeout', 'warning');
				}
			});
		}

		function isEmpty(obj) {
			for (var key in obj) {
				if (obj.hasOwnProperty(key)) {
					return false;
				}
			}
			return true;
		}
	}]);

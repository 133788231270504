'use strict';

angular.module('extra2App').factory('adminservice', ['$location', '$window', 'restservice', function($location, $window, restservice){
  var bu = null;

    var getBu = function() {
      return bu;
    };

    var setBu = function(val){
      bu = val;
    };

	return {
        setBu: setBu,
        getBu: getBu
	};
}]);

'use strict';

angular.module('extra2App')
   .directive('ckVouchers', ['$translatePartialLoader', '$translate', 'restservice', 'localizationservice', '$window',
        function ($translatePartialLoader, $translate, restservice, localizationservice, $window) {
            return {
               templateUrl: 'views/directives/vouchers.html',
               restrict: 'E',
               scope: { module: '=' },
               link: function (scope) {

                  $translatePartialLoader.addPart('awards');
                  $translate.refresh();

                  scope.vouchersData = scope.module[1].values;
                  scope.expDates = [];

                  for (var i = 0; i < scope.vouchersData.length; i++) {
                     scope.expDate = scope.vouchersData[i].expirationDate;

                     var dateObj = new Date(scope.expDate);
                     var month = dateObj.getMonth() + 1;
                     scope.expDate = localizationservice.getLocalMonthLongCapitalized(month);

                     scope.expDate = scope.expDate + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear();
                     scope.expDates.push(scope.expDate);
                  }
                }
            };
         }]);
'use strict';

angular.module('extra2App')
    .directive('ckWebshop', ['$window', '$location', 'restservice', 'page', '$translatePartialLoader', '$translate', function ($window, $location, restservice, page, $translatePartialLoader, $translate) {
        return {
            templateUrl: 'views/directives/webshop.html',
            restrict: 'E',
            scope: { module: '=' },
            link: function (scope) {
              scope.screenWidth = $window.innerWidth;
              scope.webshopModule = null;

              restservice.getWebshop().then(function(result) {
                scope.webshopModule = result.data;
              });

                  angular.element($window).bind('resize', function(){
                      scope.screenWidth = $window.innerWidth;
                            scope.$digest();
                  });
                $translatePartialLoader.addPart('extra2ui');
                $translate.refresh();
            }
        };
    }]);
